import * as actionTypes from '../actionTypes';
import { IChangeCurrentPage, IChangeItemsPerPage, IPutInfoAction, ISetInfoOfCountry } from '../actions';
import { IStore, INumberDictionary } from '../interfaces';
import { ConfederationsEnum, IAvailableCompetition, IScarfInfo, MatchOrderCategoriesEnum } from '../interfaces/clientInterfaces';
import { ALL_COUNTRIES_NAME, initialConfederations, initialState, SELECTED_COUNTRIES } from '../modules/constants';
import { IDBCity, IDBCompetition, IDBStadium } from '../interfaces/dbInterfaces';
import { Teams } from '../utils/teams';
import { Editions } from '../utils/editions';
import { GroupScarves } from '../utils/groupScarves';
import { SideTree } from '../utils/sideTree';
import { Matches } from '../utils/matches';
import { Competitions } from '../utils/competitions';
import { Countries } from '../utils/countries';

export default function fetchReducer(state: Readonly<IStore> = initialState, action: any): IStore {
	switch (action.type) {
		case actionTypes.PUT_DB_INFO: {
			const { dbTeams, dbEditions, dbCities, dbCompetitions, dbScarves, dbStadiums, dbMatchScarves } = action as IPutInfoAction;

			const stadiumMap: Map<number, IDBStadium> = new Map();
			const citiesMap: Map<number, IDBCity> = new Map();
			const scarvesSet: Set<number> = new Set();
			const competitionsCodesMap: Map<string, IDBCompetition> = new Map();
			const competitionsDictionary: INumberDictionary<IDBCompetition> = {};
			const competitionsWithEvolution: INumberDictionary<boolean> = {};
			const competitionsTrophies = new Map<number, number[]>();
			const availableCompetitions: IAvailableCompetition[] = [];

			dbCities.forEach(x => citiesMap.set(x.city_id, x));
			dbStadiums.forEach(x => stadiumMap.set(x.stadium_id, x));
			dbScarves.forEach(x => scarvesSet.add(x.team_id));
			const orderedCompetitions = Competitions.orderDbCompetitions(dbCompetitions);
			orderedCompetitions.forEach(dbCompetition => {
				competitionsCodesMap.set(`${dbCompetition.region_code}_${dbCompetition.competition_code}`, dbCompetition);
				competitionsDictionary[dbCompetition.competition_id] = dbCompetition;
				if (dbCompetition.hasEvolution) {
					competitionsWithEvolution[dbCompetition.competition_id] = true;
					availableCompetitions.push({
						id: dbCompetition.competition_id,
						name: dbCompetition.name,
						competitionCode: `${dbCompetition.region_code}_${dbCompetition.competition_code}`,
						regionCode: dbCompetition.region_code,
						isContinental: dbCompetition.isContinental,
						totalEditions: dbCompetition.totalEditions
					});
				}
				const trophyYears: number[] = [];
				if (dbCompetition.trophies) {
					dbCompetition.trophies.split(';').forEach(trophyYearString => {
						const trophyYear = parseInt(trophyYearString);
						if (!Number.isNaN(trophyYear)) {
							trophyYears.push(trophyYear);
						}
					});
				}
				competitionsTrophies.set(dbCompetition.competition_id, trophyYears);
			});
			//#endregion
			const { honoursByTeamMap, competitionEvolutions } = Editions.getInformationFromEditions(dbEditions, competitionsWithEvolution, competitionsTrophies, competitionsDictionary);
			const { clientTeams, scarves, teamsById, teamsByCountryCode, countryCodes, teamStadiumCoordinatesMap, exchangeableScarves, stadiumCluster, scarvesByTeamMap } = Teams.getTeamsInfo(
				dbTeams,
				dbScarves,
				honoursByTeamMap,
				competitionsCodesMap,
				competitionsDictionary,
				citiesMap,
				stadiumMap
			);

			const { currentGroupScarves, lastScarves, totalPages } = GroupScarves.getScarfGroups(scarves, teamsByCountryCode, state.ui.currentCountry, state.orderAndPaging.orderBy, state.orderAndPaging.allPagingInfo, clientTeams, dbScarves, scarvesByTeamMap);
			const { matchScarfGroups, rMMatchScarfGroups } = Matches.getMatchScarves(dbMatchScarves, teamsById, competitionsDictionary);
			const categories = [MatchOrderCategoriesEnum.chronologically, MatchOrderCategoriesEnum.round, MatchOrderCategoriesEnum.competition];
			const currentMatchScarfGroups = Matches.orderMatchScarfGroups(matchScarfGroups, state.orderAndPaging.orderBy.matchAscending, categories);

			const rmCategories = [MatchOrderCategoriesEnum.chronologically];
			const currentRMMatchScarfGroups = Matches.orderMatchScarfGroups(rMMatchScarfGroups, state.orderAndPaging.orderBy.matchAscending, rmCategories);

			const teamSideEntries = SideTree.getTeamSideTreeEntries(countryCodes, scarvesByTeamMap, teamsByCountryCode);
			const matchesSideEntries = SideTree.getMatchesSideTreeEntries(matchScarfGroups, rMMatchScarfGroups);
			const sideTreeEntries = SideTree.getSideTreeEntries(teamSideEntries, matchesSideEntries);
			return {
				...state,
				teams: {
					teamsById,
					teamsByCountryCode,
					teams: clientTeams
				},
				scarves: {
					scarvesByTeamMap,
					lastScarves,
					exchangeableScarves,
					currentGroupScarves,
					scarves
				},
				matches: {
					matchScarfGroups: currentMatchScarfGroups,
					currentMatchScarfGroups,
					rMMatchScarfGroups,
					currentRMMatchScarfGroups,
				},
				orderAndPaging: {
					...state.orderAndPaging,
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						totalPages
					}
				},
				map: {
					...state.map,
					stadiumCluster,
					teamStadiumCoordinatesMap,

				},
				ui: {
					...state.ui,
					trophyTooltip: null,

				},
				competitions: {
					...state.competitions,
					competitionsWithEvolution,
					competitionsDictionary,
					evolutions: competitionEvolutions,
					availableCompetitions
				},
				general: {
					...state.general,
					countryCodes
				},
				sideTree: {
					teamSideEntries,
					matchesSideEntries,
					sideTreeEntries
				}
			};
		}
		case actionTypes.SET_INFO_OF_COUNTRY: {
			const typedAction = action as ISetInfoOfCountry;
			if (!typedAction.name) {
				return state;
			}
			if (typedAction.name === SELECTED_COUNTRIES) {
				return {
					...state,
					ui: {
						...state.ui,
						currentCountry: SELECTED_COUNTRIES,
					},
					orderAndPaging: {
						...state.orderAndPaging,
						orderBy: {
							...state.orderAndPaging.orderBy,
							countryCodes: {}
						}
					}
				};
			}
			let currentGroupScarves: IScarfInfo[] = [];
			if (typedAction.name === ALL_COUNTRIES_NAME) {
				for (const team of state.teams.teams) {
					const scarves = state.scarves.scarvesByTeamMap[team.teamId];
					if (scarves && scarves.length > 0) {
						currentGroupScarves.push({
							scarves,
							city: team.city,
							team,
							stadium: team.stadium
						});
					}
				}
			} else if (Countries.isCountryCodeConfederation(typedAction.name as string)) {
				currentGroupScarves = Teams.filterByConfederation(
					state.teams.teams,
					state.scarves.scarves,
					[typedAction.name as ConfederationsEnum]
				);
			} else {
				const teamsOfCountry = Teams.getTeamsOfCountry(state.teams.teamsByCountryCode, typedAction.name as string);
				if (teamsOfCountry) {
					for (const team of teamsOfCountry) {
						const scarves = state.scarves.scarvesByTeamMap[team.teamId];
						if (scarves && scarves.length > 0) {
							currentGroupScarves.push({
								scarves,
								city: team.city,
								team,
								stadium: team.stadium
							});
						}
					}
				}
			}
			currentGroupScarves = GroupScarves.sortScarvesInfo(currentGroupScarves, state.orderAndPaging.orderBy.category, state.orderAndPaging.orderBy.ascending);
			const totalPages = Math.ceil(currentGroupScarves.length / state.orderAndPaging.allPagingInfo.teamsPerPage);
			const currentPage = 0;

			const showTreeMap = state.ui.isMobileViewActive ? false : state.ui.showTreeMap;
			return {
				...state,
				scarves: {
					...state.scarves,
					currentGroupScarves,
				},
				ui: {
					...state.ui,
					currentCountry: typedAction.name,
					showTreeMap,
					trophyTooltip: null
				},
				orderAndPaging: {
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						totalPages,
						currentPage
					},
					orderBy: {
						...state.orderAndPaging.orderBy,
						orderByRegion: null,
						confederations: typedAction.name === ALL_COUNTRIES_NAME ? initialConfederations : state.orderAndPaging.orderBy.confederations
					},
				}
			};
		}
		case actionTypes.CHANGE_CURRENT_PAGE: {
			const typedAction = action as IChangeCurrentPage;
			return {
				...state,
				orderAndPaging: {
					...state.orderAndPaging,
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						currentPage: typedAction.number
					}
				},
				ui: {
					...state.ui,
					trophyTooltip: null
				}
			};
		}
		case actionTypes.CHANGE_ITEMS_PER_PAGE: {
			const typedAction = action as IChangeItemsPerPage;
			const totalPages = Math.ceil(state.scarves.currentGroupScarves.length / typedAction.number);
			const currentPage = state.orderAndPaging.allPagingInfo.currentPage > totalPages ? totalPages - 1 : state.orderAndPaging.allPagingInfo.currentPage;
			return {
				...state,
				orderAndPaging: {
					...state.orderAndPaging,
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						teamsPerPage: typedAction.number,
						totalPages,
						currentPage
					}
				},
				ui: {
					...state.ui,
					trophyTooltip: null
				}
			};
		}
		default: {
			return state;
		}
	}
}
