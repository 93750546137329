import * as actionTypes from '../actionTypes';
import { IChangeOrderCategory, IFilterCompetition, IChangeMatchOrderCategory, IFilterConfederation, IFilterByRegion } from '../actions';
import { IStore } from '../interfaces';
import { IScarfInfo, EnumSection } from '../interfaces/clientInterfaces';
import { initialState } from '../modules/constants';
import { Teams } from '../utils/teams';
import { GroupScarves } from '../utils/groupScarves';
import { Matches } from '../utils/matches';
import { Countries } from '../utils/countries';

export default function orderAndFilterReducer(state: Readonly<IStore> = initialState, action: any) {
	switch (action.type) {
		case actionTypes.CHANGE_ORDER_CATEGORY: {
			const typedAction = action as IChangeOrderCategory;
			if (typedAction.category !== state.orderAndPaging.orderBy.category) {
				const category = typedAction.category;
				let currentGroupScarves: IScarfInfo[] = GroupScarves.sortScarvesInfo(state.scarves.currentGroupScarves, typedAction.category, state.orderAndPaging.orderBy.ascending);
				const showFilterArea = state.ui.isMobileViewActive ? false : state.ui.showFilterArea;
				currentGroupScarves = state.orderAndPaging.orderBy.orderByRegion ? currentGroupScarves.filter(team => team.city?.region === state.orderAndPaging.orderBy.orderByRegion) : currentGroupScarves;
				const teamsPerPage = state.ui.isReducedView ? 50 : state.orderAndPaging.allPagingInfo.teamsPerPage;
				const totalPages = Math.ceil(currentGroupScarves.length / teamsPerPage);
				
				return {
					...state,
					orderAndPaging: {
						...state.orderAndPaging,
						orderBy: {
							...state.orderAndPaging.orderBy,
							category
						},
						allPagingInfo: {
							...state.orderAndPaging.allPagingInfo,
							totalPages,
							teamsPerPage
						}
					},
					ui: {
						...state.ui,
						showFilterArea,
						trophyTooltip: null,
					},
					scarves: {
						...state.scarves,
						currentGroupScarves
					}
				};
			}
			return state;
		}
		case actionTypes.CHANGE_ORDER_ASCENDING: {
			const ascending = !state.orderAndPaging.orderBy.ascending;
			let currentGroupScarves: IScarfInfo[] = GroupScarves.sortScarvesInfo(state.scarves.currentGroupScarves, state.orderAndPaging.orderBy.category, ascending);
			const showFilterArea = state.ui.isMobileViewActive ? false : state.ui.showFilterArea;
			currentGroupScarves = state.orderAndPaging.orderBy.orderByRegion ? currentGroupScarves.filter(team => team.city?.region === state.orderAndPaging.orderBy.orderByRegion) : currentGroupScarves;
			const teamsPerPage = state.ui.isReducedView ? 50 : state.orderAndPaging.allPagingInfo.teamsPerPage;
			const totalPages = Math.ceil(currentGroupScarves.length / teamsPerPage);
			
			return {
				...state,
				scarves: {
					...state.scarves,
					currentGroupScarves
				},
				orderAndPaging: {
					...state.orderAndPaging,
					orderBy: {
						...state.orderAndPaging.orderBy,
						ascending
					},
					allPaginInfo: {
						...state.orderAndPaging.allPagingInfo,
						totalPages,
						teamsPerPage
					}
				},
				ui: {
					...state.ui,
					showFilterArea,
					trophyTooltip: null
				}
			};
		}

		case actionTypes.FILTER_CONFEDERATION: {
			const typedAction = action as IFilterConfederation;
			let confederations = state.orderAndPaging.orderBy.confederations.map(x => x);
			if (confederations.findIndex(x => x === typedAction.confederation) > -1) {
				confederations = confederations.filter(x => x !== typedAction.confederation);
			} else {
				confederations.push(typedAction.confederation);
			}
			let currentGroupScarves: IScarfInfo[] = Teams.filterByConfederation(
				state.teams.teams,
				state.scarves.scarves,
				confederations
			);
			currentGroupScarves = GroupScarves.sortScarvesInfo(currentGroupScarves, state.orderAndPaging.orderBy.category, state.orderAndPaging.orderBy.ascending);
			const totalPages = Math.ceil(currentGroupScarves.length / state.orderAndPaging.allPagingInfo.teamsPerPage);
			const currentPage = state.orderAndPaging.allPagingInfo.currentPage > totalPages ? totalPages : state.orderAndPaging.allPagingInfo.currentPage;
			return {
				...state,
				scarves: {
					...state.scarves,
					currentGroupScarves
				},
				orderAndPaging: {
					orderBy: {
						...state.orderAndPaging.orderBy,
						confederations
					},
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						totalPages,
						currentPage
					}
				}

			};
		}
		case actionTypes.FILTER_COMPETITION: {
			const typedAction = action as IFilterCompetition;
			let matchCompetition = state.orderAndPaging.orderBy.matchCompetition;
			if (matchCompetition.findIndex(x => x === typedAction.competition) > -1) {
				matchCompetition = matchCompetition.filter(x => x !== typedAction.competition);
			} else {
				matchCompetition.push(typedAction.competition);
			}
			let currentMatchScarfGroups = Matches.filterMatches(matchCompetition, state.matches.matchScarfGroups);
			currentMatchScarfGroups = Matches.orderMatchScarfGroups(currentMatchScarfGroups, state.orderAndPaging.orderBy.matchAscending, state.orderAndPaging.orderBy.matchCategory);

			return {
				...state,
				matches: {
					...state.matches,
					currentMatchScarfGroups
				},
				orderAndPaging: {
					...state.orderAndPaging,
					orderBy: {
						...state.orderAndPaging.orderBy,
						matchCompetition
					}
				}
			};
		}
		case actionTypes.CHANGE_MATCH_ORDER_ASCENDING: {
			if (state.ui.section === EnumSection.matches) {
				let currentMatchScarfGroups = Matches.filterMatches(state.orderAndPaging.orderBy.matchCompetition, state.matches.matchScarfGroups);

				const matchAscending = !state.orderAndPaging.orderBy.matchAscending;
				currentMatchScarfGroups = Matches.orderMatchScarfGroups(currentMatchScarfGroups, matchAscending, state.orderAndPaging.orderBy.matchCategory);
				return {
					...state,
					orderAndPaging: {
						...state.orderAndPaging,
						orderBy: {
							...state.orderAndPaging.orderBy,
							matchAscending
						}
					},
					matches: {
						...state.matches,
						currentMatchScarfGroups
					},
					ui: {
						...state.ui,
						showFilterArea: !state.ui.isMobileViewActive
					}
				};
			} else {
				let currentRMMatchScarfGroups = Matches.filterMatches(state.orderAndPaging.orderBy.matchCompetition, state.matches.rMMatchScarfGroups);

				const matchAscending = !state.orderAndPaging.orderBy.matchAscending;
				currentRMMatchScarfGroups = Matches.orderMatchScarfGroups(currentRMMatchScarfGroups, matchAscending, state.orderAndPaging.orderBy.matchCategory);
				return {
					...state,
					orderAndPaging: {
						...state.orderAndPaging,
						orderBy: {
							...state.orderAndPaging.orderBy,
							matchAscending
						}
					},
					ui: {
						...state.ui,
						showFilterArea: !state.ui.isMobileViewActive
					},
					matches: {
						...state.matches,
						currentRMMatchScarfGroups
					}
				};
			}

		}
		case actionTypes.CHANGE_MATCH_ORDER: {
			if (state.ui.section === EnumSection.matches) {
				const typedAction = action as IChangeMatchOrderCategory;

				let currentMatchScarfGroups = Matches.filterMatches(state.orderAndPaging.orderBy.matchCompetition, state.matches.matchScarfGroups);

				let categories = [...state.orderAndPaging.orderBy.matchCategory];
				const isAlreadyFilteredBy = categories.findIndex(c => c === typedAction.category) > -1;
				if (isAlreadyFilteredBy) {
					categories = categories.filter(c => c !== typedAction.category);
				} else {
					categories.push(typedAction.category);
				}

				currentMatchScarfGroups = Matches.orderMatchScarfGroups(currentMatchScarfGroups, state.orderAndPaging.orderBy.matchAscending, categories);

				return {
					...state,
					matches: {
						...state.matches,
						currentMatchScarfGroups
					},
					orderAndPaging: {
						...state.orderAndPaging,
						orderBy: {
							...state.orderAndPaging.orderBy,
							matchCategory: categories
						},
					},
					ui: {
						...state.ui,
						showFilterArea: !state.ui.isMobileViewActive
					}
				};
			} else {
				const typedAction = action as IChangeMatchOrderCategory;

				let currentRMMatchScarfGroups = Matches.filterMatches(state.orderAndPaging.orderBy.matchCompetition, state.matches.rMMatchScarfGroups);

				let categories = [...state.orderAndPaging.orderBy.matchCategory];
				const isAlreadyFilteredBy = categories.findIndex(c => c === typedAction.category) > -1;
				if (isAlreadyFilteredBy) {
					categories = categories.filter(c => c !== typedAction.category);
				} else {
					categories.push(typedAction.category);
				}

				currentRMMatchScarfGroups = Matches.orderMatchScarfGroups(currentRMMatchScarfGroups, state.orderAndPaging.orderBy.matchAscending, categories);

				return {
					...state,
					matches: {
						...state.matches,
						currentRMMatchScarfGroups
					},
					orderAndPaging: {
						...state.orderAndPaging,
						orderBy: {
							...state.orderAndPaging.orderBy,
							matchCategory: categories
						}
					},
					ui: {
						...state.ui,
						showFilterArea: !state.ui.isMobileViewActive
					}
				};
			}
		}
		case actionTypes.FILTER_BY_REGION: {
			const { region } = action as IFilterByRegion;
			if (!Countries.isCountrySelectableByRegion(state.ui.currentCountry)) {
				return state;
			}
			let currentGroupScarves: IScarfInfo[] = [];
			const teamsOfCountryCode = state.teams.teamsByCountryCode[state.ui.currentCountry];
			if (teamsOfCountryCode) {
				for (const team of teamsOfCountryCode) {
					const scarves = state.scarves.scarvesByTeamMap[team.teamId];
					if (scarves && scarves.length > 0) {
						if (region) {
							if (team.city?.region === region) {
								currentGroupScarves.push({
									scarves,
									city: team.city,
									team,
									stadium: team.stadium
								});		
							}
						} else {
							currentGroupScarves.push({
								scarves,
								city: team.city,
								team,
								stadium: team.stadium
							});
						}
					}
				}
			}
			currentGroupScarves = GroupScarves.sortScarvesInfo(currentGroupScarves, state.orderAndPaging.orderBy.category, state.orderAndPaging.orderBy.ascending);
			const teamsPerPage = state.ui.isReducedView ? 50 : state.orderAndPaging.allPagingInfo.teamsPerPage;
			const totalPages = Math.ceil(currentGroupScarves.length / teamsPerPage);
			return {
				...state,
				orderAndPaging: {
					...state.orderAndPaging,
					orderBy: {
						...state.orderAndPaging.orderBy,
						orderByRegion: region
					},
					allPagingInfo: {
						totalPages,
						teamsPerPage,
						currentPage: 0
					}
				},
				ui: {
					...state.ui,
					trophyTooltip: null,
				},
				scarves: {
					...state.scarves,
					currentGroupScarves
				}
			};
		}
		default: {
			return state;
		}
	}
}
