import { ConfederationsEnum, ICenterOfCountry } from '../interfaces/clientInterfaces';

export class Countries {

	public static isCountrySelectableByRegion = (countryCode: string) => {
		return countryCode === 'IT' ||
			countryCode === 'ES' ||
			countryCode === 'DE' ||
			countryCode === 'FR' ||
			countryCode === 'AT' ||
			countryCode === 'HR' ||
			countryCode === 'IE' ||
			countryCode === 'BH' ||
			countryCode === 'PT' ||
			countryCode === 'BR' ||
			countryCode === 'AR' ||
			countryCode === 'BE' ||
			countryCode === 'NL' ||
			countryCode === 'CH' ||
			countryCode === 'SK' ||
			countryCode === 'CZ' ||
			countryCode === 'RS' ||
			countryCode === 'DK' ||
			countryCode === 'PL' ||
			countryCode === 'SE' ||
			countryCode === 'HE' ||
			countryCode === 'RO' ||
			countryCode === 'SI' ||
			countryCode === 'EN' ||
			countryCode === 'SC' ||
			countryCode === 'NI' ||
			countryCode === 'NO' ||
			countryCode === 'FI' ||
			countryCode === 'WL' ||
			countryCode === 'TK' ||
			countryCode === 'CL' ||
			countryCode === 'US' ||
			countryCode === 'PE' ||
			countryCode === 'BG' ||
			countryCode === 'UA' ||
			countryCode === 'RU' ||
			countryCode === 'HU' ||
			countryCode === 'CO' ||
			countryCode === 'CA' 
	}

	public static getTitlesOfFormerCountries = (
		countryCode: string,
		countryCode2: string,
		years: number[]
	): {
		countryCode: string;
		numberOfTitles: number;
	}[] => {
		let formerCountry = {
			countryCode: countryCode2,
			numberOfTitles: 0
		};
		let currentCountry = {
			countryCode,
			numberOfTitles: 0
		};
		if (countryCode2 === 'YU' || countryCode2 === 'UR' || countryCode2 === 'ZK') {
			for (const year of years) {
				if (year <= 1993) {
					formerCountry = {
						...formerCountry,
						numberOfTitles: formerCountry.numberOfTitles + 1
					};
				} else {
					currentCountry = {
						...currentCountry,
						numberOfTitles: currentCountry.numberOfTitles + 1
					};
				}
			}
			return [
				formerCountry,
				currentCountry
			];
		} else {
			return [
				{
					countryCode,
					numberOfTitles: years.length
				}
			];
		}

	}

	public static getConfederationOfCountry = (countryCode: string): ConfederationsEnum => {
		switch (countryCode) {
			case 'AB':
				return ConfederationsEnum.eu;
			case 'AD':
				return ConfederationsEnum.eu;
			case 'AE':
				return ConfederationsEnum.as;
			case 'AM':
				return ConfederationsEnum.eu;
			case 'AO':
				return ConfederationsEnum.af;
			case 'AR':
				return ConfederationsEnum.sa;
			case 'AT':
				return ConfederationsEnum.eu;
			case 'AU':
				return ConfederationsEnum.as;
			case 'AZ':
				return ConfederationsEnum.eu;
			case 'BA':
				return ConfederationsEnum.as;
			case 'BB':
				return ConfederationsEnum.na;
			case 'BE':
				return ConfederationsEnum.eu;
			case 'BZ':
				return ConfederationsEnum.na;
			case 'BG':
				return ConfederationsEnum.eu;
			case 'BH':
				return ConfederationsEnum.eu;
			case 'BO':
				return ConfederationsEnum.sa;
			case 'BR':
				return ConfederationsEnum.sa;
			case 'BY':
				return ConfederationsEnum.eu;
			case 'CA':
				return ConfederationsEnum.na;
			case 'CD':
				return ConfederationsEnum.af;
			case 'CM':
				return ConfederationsEnum.af;
			case 'CO':
				return ConfederationsEnum.sa;
			case 'CG':
				return ConfederationsEnum.af;
			case 'CH':
				return ConfederationsEnum.eu;
			case 'CL':
				return ConfederationsEnum.sa;
			case 'CN':
				return ConfederationsEnum.as;
			case 'CR':
				return ConfederationsEnum.na;
			case 'CU':
				return ConfederationsEnum.na;
			case 'CV':
				return ConfederationsEnum.af;
			case 'CY':
				return ConfederationsEnum.eu;
			case 'CZ':
				return ConfederationsEnum.eu;
			case 'DE':
				return ConfederationsEnum.eu;
			case 'DK':
				return ConfederationsEnum.eu;
			case 'DZ':
				return ConfederationsEnum.af;
			case 'EC':
				return ConfederationsEnum.sa;
			case 'EG':
				return ConfederationsEnum.af;
			case 'EN':
				return ConfederationsEnum.eu;
			case 'EE':
				return ConfederationsEnum.eu;
			case 'ES':
				return ConfederationsEnum.eu;
			case 'ET':
				return ConfederationsEnum.af;
			case 'FI':
				return ConfederationsEnum.eu;
			case 'GE':
				return ConfederationsEnum.eu;
			case 'GI':
				return ConfederationsEnum.eu;
			case 'GL':
				return ConfederationsEnum.na;
			case 'GH':
				return ConfederationsEnum.af;
			case 'GN':
				return ConfederationsEnum.af;
			case 'FO':
				return ConfederationsEnum.eu;
			case 'FR':
				return ConfederationsEnum.eu;
			case 'HE':
				return ConfederationsEnum.eu;
			case 'HK':
				return ConfederationsEnum.as;
			case 'HN':
				return ConfederationsEnum.na;
			case 'HR':
				return ConfederationsEnum.eu;
			case 'HU':
				return ConfederationsEnum.eu;
			case 'ID':
				return ConfederationsEnum.as;
			case 'IE':
				return ConfederationsEnum.eu;
			case 'IL':
				return ConfederationsEnum.eu;
			case 'IN':
				return ConfederationsEnum.as;
			case 'IR':
				return ConfederationsEnum.as;
			case 'IQ':
				return ConfederationsEnum.as;
			case 'IT':
				return ConfederationsEnum.eu;
			case 'JP':
				return ConfederationsEnum.as;
			case 'KR':
				return ConfederationsEnum.as;
			case 'KZ':
				return ConfederationsEnum.eu;
			case 'LB':
				return ConfederationsEnum.as;
			case 'LI':
				return ConfederationsEnum.eu;
			case 'LT':
				return ConfederationsEnum.eu;
			case 'LV':
				return ConfederationsEnum.eu;
			case 'LU':
				return ConfederationsEnum.eu;
			case 'MA':
				return ConfederationsEnum.af;
			case 'MD':
				return ConfederationsEnum.eu;
			case 'ME':
				return ConfederationsEnum.eu;
			case 'MT':
				return ConfederationsEnum.eu;
			case 'MX':
				return ConfederationsEnum.na;
			case 'MV':
				return ConfederationsEnum.as;
			case 'MN':
				return ConfederationsEnum.as;
			case 'MO':
				return ConfederationsEnum.eu;
			case 'MY':
				return ConfederationsEnum.as;
			case 'NI':
				return ConfederationsEnum.eu;
			case 'NL':
				return ConfederationsEnum.eu;
			case 'NZ':
				return ConfederationsEnum.oc;
			case 'NC':
				return ConfederationsEnum.na;
			case 'SV':
				return ConfederationsEnum.na;
			case 'NO':
				return ConfederationsEnum.eu;
			case 'PE':
				return ConfederationsEnum.sa;
			case 'PY':
				return ConfederationsEnum.sa;
			case 'PL':
				return ConfederationsEnum.eu;
			case 'PT':
				return ConfederationsEnum.eu;
			case 'QT':
				return ConfederationsEnum.as;
			case 'OM':
				return ConfederationsEnum.as;
			case 'RO':
				return ConfederationsEnum.eu;
			case 'RS':
				return ConfederationsEnum.eu;
			case 'RU':
				return ConfederationsEnum.eu;
			case 'KS':
				return ConfederationsEnum.as;
			case 'SC':
				return ConfederationsEnum.eu;
			case 'SE':
				return ConfederationsEnum.eu;
			case 'SI':
				return ConfederationsEnum.eu;
			case 'SM':
				return ConfederationsEnum.eu;
			case 'SK':
				return ConfederationsEnum.eu;
			case 'SR':
				return ConfederationsEnum.na;
			case 'TN':
				return ConfederationsEnum.af;
			case 'TK':
				return ConfederationsEnum.eu;
			case 'UA':
				return ConfederationsEnum.eu;
			case 'UB':
				return ConfederationsEnum.as;
			case 'US':
				return ConfederationsEnum.na;
			case 'XK':
				return ConfederationsEnum.eu;
			case 'UY':
				return ConfederationsEnum.sa;
			case 'VE':
				return ConfederationsEnum.sa;
			case 'WL':
				return ConfederationsEnum.eu;
			case 'ZA':
				return ConfederationsEnum.af;
			case 'CI':
				return ConfederationsEnum.af;
			case 'GT':
				return ConfederationsEnum.na;
			case 'TT':
				return ConfederationsEnum.na;
			case 'NG':
				return ConfederationsEnum.af;
			case 'TH':
				return ConfederationsEnum.as;
			case 'TZ':
				return ConfederationsEnum.af;
			case 'SD':
				return ConfederationsEnum.af;
			case 'MI':
				return ConfederationsEnum.af;
			case 'UG':
				return ConfederationsEnum.af;
			case 'HT':
				return ConfederationsEnum.na;
			case 'SY':
				return ConfederationsEnum.as;
			case 'KE':
				return ConfederationsEnum.af;
			case 'ZM':
				return ConfederationsEnum.af;
			case 'TM':
				return ConfederationsEnum.as;
			case 'TJ':
				return ConfederationsEnum.as;
			case 'KW':
				return ConfederationsEnum.as;
			default:
				return ConfederationsEnum.eu;
		}
	}

	public static getFlagOfCountry = (
		year: number,
		competitionRegionCode: string,
		teamCountryCode: string,
		teamCountryCode2: string | undefined,
		isNational: boolean
	): string => {
		if (teamCountryCode2 === 'YU') {
			if (year <= 1992) {
				return competitionRegionCode === 'YU' ? `${teamCountryCode}_YU` : 'YU';
			} else {
				if (teamCountryCode === 'RS' || teamCountryCode === 'ME') {
					if (year <= 2006) {
						return 'SCG'
					}
				}
				return teamCountryCode;
			}
		} else if (teamCountryCode2 === 'UR') {
			if (year <= 1994) {
				return competitionRegionCode === 'UR' ? `${teamCountryCode}_UR` : 'UR';
			}
			return teamCountryCode
		} else if (teamCountryCode2 === 'DR') {
			if (year <= 1989) {
				return 'DR';
			}
			return teamCountryCode
		} if (teamCountryCode2 === 'ZK') {
			if (year > 1918 && year <= 1992) {
				return 'ZK';
			}
			if (year <= 1918) {
				return teamCountryCode === 'CZ' ? 'CZ_1918' : 'HU_1918';

			}
			return teamCountryCode;
		}
		switch (teamCountryCode) {
			case 'AB':
				return year <= 1992 ? 'AB_1992' : teamCountryCode;
			case 'AT':
				return year <= 1918 ? 'AT_1918' : year <= 1938 ? 'AT_1938' : year <= 1945 ? 'AT_1945': teamCountryCode;
			case 'BG':
				return year < 1990 ? 'BG_67' : teamCountryCode;
			case 'ES':
				return year < 1936 ? 'ES_36' : year < 1977 ? 'ES_77' : isNational && (year === 1984 || year === 1980) ? 'OLY' : teamCountryCode;
			case 'HE':
				return year < 1969
					? 'HE_69'
					: year < 1971
						? 'HE_70'
						: year < 1975
							? 'HE_75'
							: year < 1978 ? 'HE_69' : teamCountryCode;
			case 'IT':
				return year < 1947 ? 'IT_46' : isNational && (year === 1984 || year === 1980) ? 'OLY' : teamCountryCode;
			case 'FR':
				return year < 1975 ? teamCountryCode : year < 2022 ? 'FR_21' : teamCountryCode;
			case 'EG':
				return year < 1959 ? 'EG_58' : year < 1972 ? 'EG_72' : teamCountryCode;
			case 'HU':
				return year <= 1918 ? 'HU_1918' : year < 1957 ? 'HU_56' : year < 1958 ? 'HU_57' : teamCountryCode;
			case 'RU':
				return year < 1994 ? 'RU_93' : teamCountryCode;
			case 'YU':
				return year < 1994 ? teamCountryCode : 'SCG';
			case 'DE':
				return year <= 1945 ? 'DE_1945' : teamCountryCode;
			case 'CA':
				return year <= 1965 ? 'CA_1965' : teamCountryCode;
			case 'LY':
				return year <= 2011 ? 'LY_2011' : teamCountryCode;
			case 'SD':
				return year <= 1969 ? 'SD_1969' : teamCountryCode;
			case 'AF':
				return year <= 2021 ? 'AF_2021' : teamCountryCode;
			case 'PG':
				return year <= 1965 ? 'AU' : year <= 1970 ? 'PG_1970' : year === 1971 ? 'PG_1971' : teamCountryCode;
			case 'THT':
				return year <= 1990 ? 'FR' : teamCountryCode;
			case 'NEC':
				return year <= 1990 ? 'FR' : year <= 2010 ? 'NEC_2010' : teamCountryCode;
			case 'NR':
				return year <= 1966 ? 'NR_1966' : teamCountryCode;
			case 'FJ':
				return year <= 1970 ? 'FJ_1970' : teamCountryCode;
			case 'PH':
				return year <= 1985 ? 'PH_1985' : teamCountryCode;
			case 'RO':
				return year <= 1989 ? 'RO_1989' : teamCountryCode;
			case 'IE':
				return year <= 1922 ? 'IE_1922' : teamCountryCode;
			case 'WL':
				return year <= 1953 ? 'WL_1953' : year <= 1959 ? 'WL_1959' : teamCountryCode;
			case 'TW': {
				if (isNational) {
					return year <= 1979 ? teamCountryCode : 'TW_1979';
				}
				return teamCountryCode;
			}
			default:
				return teamCountryCode;
		}
	}

	public static getCenterOfCountry = (name: string): ICenterOfCountry => {
		switch (name) {
			case 'Albania':
				return {
					center: [41.3313, 20.0339],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Andorra':
				return {
					center: [42.5049, 1.5176],
					zoom: 11,
					mobileZoom: 9
				};
			case 'Angola':
				return {
					center: [-10.3951, 17, 3427],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Argentina':
				return {
					center: [-34.6272, -58.5136],
					zoom: 11,
					mobileZoom: 10
				};
			case 'Armenia':
				return {
					center: [40.3586, 44.5507],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Austria':
				return {
					center: [47.5441, 14.8543],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Australia':
				return {
					center: [-24.9338, 134.4615],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Azerbaijan':
				return {
					center: [40.3655, 49.8109],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Belgium':
				return {
					center: [50.8525, 4.3704],
					zoom: 10,
					mobileZoom: 9
				};
			case 'Bahrain':
				return {
					center: [26.0667, 50.5577],
					zoom: 10,
					mobileZoom: 9
				};
			case 'Barbados':
				return {
					center: [13.1939, -59.5432],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Belize':
				return {
					center: [17.1899, -88.4976],
					zoom: 10,
					mobileZoom: 9
				};
			case 'Bulgaria':
				return {
					center: [42.7558, 24.1689],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Bolivia':
				return {
					center: [-16.5, -68.15],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Bosnia and Herzegovina':
				return {
					center: [44.3178, 17.8088],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Brazil':
				return {
					center: [-22.9449, -44.9040],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Belarus':
				return {
					center: [53.4994, 28.7418],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Cameroon':
				return {
					center: [7.3697, 12.3547],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Cambodia':
				return {
					center: [11.6230, 104.7796],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Canada':
				return {
					center: [49.2162, -101.4066],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Cape Verde':
				return {
					center: [16.5388, -23.0418],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Colombia':
				return {
					center: [4.7153, -74.2891],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Costa Rica':
				return {
					center: [9.7489, -83.7534],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Switzerland':
				return {
					center: [47.2155, 7.8907],
					zoom: 10,
					mobileZoom: 9
				};
			case 'Chile':
				return {
					center: [-33.4618, -70.6737],
					zoom: 11,
					mobileZoom: 10
				};
			case 'China':
				return {
					center: [39.9272, 116.4013],
					zoom: 3,
					mobileZoom: 3
				};
			case 'Cyprus':
				return {
					center: [34.9481, 33.2763],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Czech Republic':
				return {
					center: [50.1220, 15.0075],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Germany':
				return {
					center: [50.1508, 10.0664],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Greenland':
				return {
					center: [71.7069, -42.6043],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Denmark':
				return {
					center: [56.0702, 11.0880],
					zoom: 8,
					mobileZoom: 7
				};
			case 'DR Congo':
				return {
					center: [4.0383, 21.7587],
					zoom: 6,
					mobileZoom: 4
				};
			case 'Algeria':
				return {
					center: [36.7388, 3.0903],
					zoom: 10,
					mobileZoom: 10
				};
			case 'El Salvador':
				return {
					center: [13.7942, -88.8965],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Ecuador':
				return {
					center: [-0.9414, -79.5643],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Egypt':
				return {
					center: [30.1486, 31.2271],
					zoom: 10,
					mobileZoom: 10
				};
			case 'England':
				return {
					center: [52.4606, -0.8128],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Estonia':
				return {
					center: [59.4241, 24.7671],
					zoom: 8,
					mobileZoom: 8
				};
			case 'Ethiopia':
				return {
					center: [9.1450, 40.4897],
					zoom: 8,
					mobileZoom: 8
				};
			case 'Spain':
				return {
					center: [40.3500, -2.1953],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Faroe Islands':
				return {
					center: [62.1359, -6.8512],
					zoom: 10,
					mobileZoom: 9
				};
			case 'Finland':
				return {
					center: [59.4241, 24.7671],
					zoom: 8,
					mobileZoom: 7
				};
			case 'France':
				return {
					center: [46.4104, 2.3301],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Georgia':
				return {
					center: [41.9258, 43.9723],
					zoom: 9,
					mobileZoom: 9
				};
			case 'Gibraltar':
				return {
					center: [36.1392, -5.3472],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Greece':
				return {
					center: [39.4224, 22.6572],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Ghana':
				return {
					center: [7.9465, 1.0232],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Guinea':
				return {
					center: [9.9456, -9.6966],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Croatia':
				return {
					center: [45.8063, 15.9823],
					zoom: 11,
					mobileZoom: 10
				};
			case 'Honduras':
				return {
					center: [14.0772, -87.1761],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Hong Kong':
				return {
					center: [22.3193, 114.1694],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Hungary':
				return {
					center: [47.1277, 19.2236],
					zoom: 9,
					mobileZoom: 8
				};
			case 'India':
				return {
					center: [19.5902, 78.2881],
					zoom: 6,
					mobileZoom: 6
				};
			case 'Indonesia':
				return {
					center: [-6.1671, 106.8325],
					zoom: 10,
					mobileZoom: 10
				};
			case 'Iceland':
				return {
					center: [64.0989, -21.8338],
					zoom: 7,
					mobileZoom: 7
				};
			case 'Iraq':
				return {
					center: [33.2232, 43.6793],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Iran':
				return {
					center: [35.6735, 51.1965],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Ireland':
				return {
					center: [53.1089, -7.8443],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Israel':
				return {
					center: [32.0711, 34.7794],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Italy':
				return {
					center: [44.1075, 11.1813],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Japan':
				return {
					center: [34.8188, 137.5776],
					zoom: 4,
					mobileZoom: 3
				};
			case 'Kazakhstan':
				return {
					center: [50.9801, 71.2029],
					zoom: 4,
					mobileZoom: 3
				};
			case 'Kosovo':
				return {
					center: [42.6348, 21.0506],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Lebanon':
				return {
					center: [33.9497, 35.6333],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Latvia':
				return {
					center: [59.9372, 24.2084],
					zoom: 10,
					mobileZoom: 9
				};
			case 'Liechtenstein':
				return {
					center: [47.1502, 9.5168],
					zoom: 11,
					mobileZoom: 10
				};
			case 'Lithuania':
				return {
					center: [54.6563, 24.1192],
					zoom: 10,
					mobileZoom: 10
				};
			case 'Luxembourg':
				return {
					center: [49.6768, 6.1267],
					zoom: 10,
					mobileZoom: 10
				};
			case 'Morocco':
				return {
					center: [33.3212, -7.1317],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Moldova':
				return {
					center: [47.4116, 28.3699],
					zoom: 9,
					mobileZoom: 8
				};
			case 'North Macedonia':
				return {
					center: [41.9871, 21.4595],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Nicaragua':
				return {
					center: [12.8654, -85.2072],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Malaysia':
				return {
					center: [4.2105, 101.9758],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Maldives':
				return {
					center: [3.2028, 73.2207],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Mongolia':
				return {
					center: [46.8625, 103.8467],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Montenegro':
				return {
					center: [42.9417, 19.3291],
					zoom: 9,
					mobileZoom: 8
				};
			case 'Malta':
				return {
					center: [35.8985, 14.4336],
					zoom: 10,
					mobileZoom: 10
				};
			case 'Mexico':
				return {
					center: [22.7140, -101.2504],
					zoom: 6,
					mobileZoom: 6
				};
			case 'Northern Ireland':
				return {
					center: [54.7003, -6.5745],
					zoom: 8,
					mobileZoom: 8
				};
			case 'Netherlands':
				return {
					center: [52.1502, 5.3028],
					zoom: 8,
					mobileZoom: 7
				};
			case 'New Zealand':
				return {
					center: [-40.9006, 174.8860],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Norway':
				return {
					center: [60.3488, 8.1420],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Oman':
				return {
					center: [21.4735, 55.9754],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Paraguay':
				return {
					center: [-25.2914, -57.5488],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Peru':
				return {
					center: [-12.0627, -77.0471],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Poland':
				return {
					center: [51.4441, 20.0362],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Portugal':
				return {
					center: [40.0284, -8.8703],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Republic of the Congo':
				return {
					center: [-0.228, 15.8277],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Romania':
				return {
					center: [45.6050, 24.7302],
					zoom: 8,
					mobileZoom: 7
				};
			case 'San Marino':
				return {
					center: [43.9430, 12.4637],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Serbia':
				return {
					center: [44.8083, 20.4452],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Suriname':
				return {
					center: [3.9193, -56.0278],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Qatar':
				return {
					center: [25.2592, 51.1858],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Russia':
				return {
					center: [55.7511, 37.6168],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Saudi Arabia':
				return {
					center: [24.7213, 46.6834],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Scotland':
				return {
					center: [55.8828, -3.7078],
					zoom: 8,
					mobileZoom: 8
				};
			case 'Sweden':
				return {
					center: [58.5439, 14.4597],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Slovenia':
				return {
					center: [46.2003, 14.9031],
					zoom: 10,
					mobileZoom: 8

				};
			case 'Slovakia':
				return {
					center: [48.4431, 19.1113],
					zoom: 9,
					mobileZoom: 8
				};
			case 'South Africa':
				return {
					center: [-30.1209, 24.4206],
					zoom: 6,
					mobileZoom: 5
				};
			case 'South Korea':
				return {
					center: [38.4878, 127.9977],
					zoom: 4,
					mobileZoom: 3
				};
			case 'Tunisia':
				return {
					center: [41.3313, 20.0339],
					zoom: 9,
					mobileZoom: 9
				};
			case 'Turkey':
				return {
					center: [41.0289, 28.9966],
					zoom: 11,
					mobileZoom: 11
				};
			case 'UAE':
				return {
					center: [24.4338, 54.4208],
					zoom: 10,
					mobileZoom: 10
				};
			case 'Ukraine':
				return {
					center: [49.8625, 30.8668],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Uzbekistan':
				return {
					center: [41.2829, 69.2815],
					zoom: 7,
					mobileZoom: 7
				};
			case 'United States':
				return {
					center: [40.7165, -100.6629],
					zoom: 5,
					mobileZoom: 5
				};
			case 'Uruguay':
				return {
					center: [-34.8904, -56.1684],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Venezuela':
				return {
					center: [10.4861, -66.9021],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Vietnam':
				return {
					center: [10.8082, 106.665],
					zoom: 11,
					mobileZoom: 11
				};
			case 'Wales':
				return {
					center: [52.3951, -3.3785],
					zoom: 8,
					mobileZoom: 7
				};
			case 'Ivory Coast':
				return {
					center: [7.54, 5.5471],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Guatemala':
				return {
					center: [15.7835, -90.2308],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Trinidad and Tobago':
				return {
					center: [10.6918, -64.2225],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Nigeria':
				return {
					center: [9.0820, 8.6753],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Thailand':
				return {
					center: [15.87, 100.9925],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Sudan':
				return {
					center: [15.5007, 32.5599],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Mali':
				return {
					center: [12.6392, -8.0029],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Uganda':
				return {
					center: [0.3476, 32.5825],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Haiti':
				return {
					center: [18.5944, -72.3074],
					zoom: 5,
					mobileZoom: 4
				};
			case 'Kenya':
				return {
					center: [-0.0236, 37.9062],
					zoom: 6,
					mobileZoom: 5
				};
			case 'Syria':
				return {
					center: [34.8021, 38.9968],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Tanzania':
				return {
					center: [-6.3728, 34.8925],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Zambia':
				return {
					center: [-13.1404, 27.8493],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Cuba':
				return {
					center: [21.5513, -79.6017],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Turkmenistan':
				return {
					center: [38.9638, 59.5776],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Tajikistan':
				return {
					center: [38.8582, 71.2479],
					zoom: 7,
					mobileZoom: 6
				};
			case 'Kuwait':
				return {
					center: [29.3141, 47.4917],
					zoom: 7,
					mobileZoom: 6
				};
			default:
				return {
					center: [46.6, 7.847874],
					zoom: 6,
					mobileZoom: 5
				};
		}
	}

	public static isCountryCodeConfederation = (countryCode: string): boolean => {
		return countryCode === 'EU' || countryCode === 'AF' || countryCode === 'AS' || countryCode === 'SA' || countryCode === 'NA' || countryCode === 'OC';
	}
}
