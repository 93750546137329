
import { IDBCity, IDBCompetition, IDBScarf, IDBStadium, IDBTeam } from '../interfaces/dbInterfaces';
import { ITeam, IHonours, ITitle, IScarf, ConfederationsEnum, IScarfInfo } from '../interfaces/clientInterfaces';
import { Competitions } from './competitions';
import { Converters } from './converters';
import { INumberDictionary, IStringDictionary } from '../interfaces';
import { Countries } from './countries';

export class Teams {

	public static getTeamsInfo = (
		dbTeams: IDBTeam[],
		dbScarves: IDBScarf[],
		honoursByTeamId: Map<number, IHonours>,
		competitionsCodesMap: Map<string, IDBCompetition>,
		competitionsDictionary: INumberDictionary<IDBCompetition>,
		citiesMap: Map<number, IDBCity>,
		stadiumMap: Map<number, IDBStadium>
	): {
		countryCodes: string[];
		teamsById: INumberDictionary<ITeam>;
		teamsByCountryCode: IStringDictionary<ITeam[]>;
		clientTeams: ITeam[];
		teamStadiumCoordinatesMap: Map<string, number[]>;
		exchangeableScarves: string[];
		stadiumCluster: { [id: number]: number[] };
		scarvesByTeamMap: INumberDictionary<IScarf[]>;
		scarves: IScarf[];
	} => {
		const scarves: IScarf[] = [];
		const countryCodes: string[] = [];
		const clientTeams: ITeam[] = [];
		const teamStadiumCoordinatesMap = new Map<string, number[]>();
		let leagueCompetitionByCountryMap = new Map<string, IDBCompetition>();
		const teamsByCountryCode: IStringDictionary<ITeam[]> = {};
		const teamsById: INumberDictionary<ITeam> = {};

		const exchangeableScarves: string[] = [];
		const scarvesSet: Set<number> = new Set();
		const stadiumCluster: { [id: number]: number[] } = {};

		const scarvesByTeamMap: INumberDictionary<IScarf[]> = {};
		dbScarves.forEach(dbScarf => {
			const scarvesByTeam = scarvesByTeamMap[dbScarf.team_id];
			const scarf = Converters.getClientScarfFromDBScarf(dbScarf);
			scarves.push(scarf);
			if (scarvesByTeam) {
				scarvesByTeamMap[dbScarf.team_id] = scarvesByTeam.concat([scarf]);
			} else {
				scarvesByTeamMap[dbScarf.team_id] = [scarf];
			}
			scarvesSet.add(dbScarf.team_id);
			if (dbScarf.exchangeable) {
				exchangeableScarves.push(dbScarf.scarfCode);
			}
		});

		for (const dbTeam of dbTeams) {
			if (!dbTeam.isNational && countryCodes.indexOf(dbTeam.country_code) === -1) {
				countryCodes.push(dbTeam.country_code);
			}
			const dbStadium = stadiumMap.get(dbTeam.stadium_id);
			const dbCity = citiesMap.get(dbTeam.city_id);
			if (dbStadium && dbCity) {
				const { overallRanking, noTitleRanking, seasonsTopPercentage, honours, updatedLeagueCompetitionByCountryMap, seasonsTopTotal, seasonsTopTotal2 } =
					Teams._processTeamRankings(dbTeam, honoursByTeamId, competitionsDictionary, competitionsCodesMap, leagueCompetitionByCountryMap);
				const clientTeam = Converters.getClientTeamFromDBTeam(
					dbTeam,
					dbStadium,
					dbCity,
					overallRanking,
					seasonsTopPercentage,
					honours,
					noTitleRanking,
					seasonsTopTotal,
					seasonsTopTotal2
				);
				clientTeams.push(clientTeam);
				leagueCompetitionByCountryMap = updatedLeagueCompetitionByCountryMap;
				teamStadiumCoordinatesMap.set(dbTeam.name, [dbStadium.north, dbStadium.east]);
				if (scarvesSet.has(clientTeam.teamId)) {
					const stadium = stadiumMap.get(dbTeam.stadium_id);
					if (stadium) {
						if (stadiumCluster.hasOwnProperty(clientTeam.stadium.stadiumId)) {
							stadiumCluster[clientTeam.stadium.stadiumId] = stadiumCluster[clientTeam.stadium.stadiumId].concat(clientTeam.teamId);
						} else {
							stadiumCluster[clientTeam.stadium.stadiumId] = [clientTeam.teamId];
						}
					}
				}
				const teamsOfCountry = teamsByCountryCode[clientTeam.countryCode];
				if (teamsOfCountry) {
					teamsByCountryCode[clientTeam.countryCode] = [...teamsOfCountry, clientTeam];
				} else {
					teamsByCountryCode[clientTeam.countryCode] = [clientTeam];
				}
				teamsById[clientTeam.teamId] = clientTeam;
			}
		}
		return {
			countryCodes,
			teamsById,
			teamsByCountryCode,
			clientTeams,
			teamStadiumCoordinatesMap,
			exchangeableScarves,
			stadiumCluster,
			scarvesByTeamMap,
			scarves
		};
	}

	private static _getSeasonsAndCategoryRanking = (
		team: IDBTeam,
		competitionsCodesMap: Map<string, IDBCompetition>,
		leagueCompetitionByCountryMap: Map<string, IDBCompetition>
	): {
		updatedLeagueCompetitionByCountryMap: Map<string, IDBCompetition>;
		seasonsRanking: number;
		categoryRanking: number;
		firstLeagueCompetition: IDBCompetition | null;
		secondLeagueCompetition: IDBCompetition | null;
		seasonsTopTotal: number;
		seasonsTopTotal2: number;
	} => {
		let categoryRanking = 0;
		let seasonsRanking = 0;
		let seasonsTopTotal = 0;
		let seasonsTopTotal2 = 0;

		let firstLeagueCompetition = leagueCompetitionByCountryMap.get(team.country_code);
		if (!firstLeagueCompetition) {
			const firstCompetition = competitionsCodesMap.get(`${team.country_code}_L`);
			if (firstCompetition) {
				firstLeagueCompetition = firstCompetition;
				leagueCompetitionByCountryMap.set(team.country_code, firstLeagueCompetition);
			}
		}
		let secondLeagueCompetition = team.country_code2 ? leagueCompetitionByCountryMap.get(team.country_code2) : null;
		if (!secondLeagueCompetition) {
			const secondCompetition = competitionsCodesMap.get(`${team.country_code2}_L`);
			if (secondCompetition && team.country_code2) {
				secondLeagueCompetition = secondCompetition;
				leagueCompetitionByCountryMap.set(team.country_code2, secondLeagueCompetition);
			}
		}
		if (firstLeagueCompetition) {
			if (team.category) {
				const parsedCategory = parseInt(team.category);
				if (!Number.isNaN(parsedCategory) && parsedCategory > 0) {
					categoryRanking = (1 / parsedCategory) * firstLeagueCompetition.weight;
				}
			}
			if (firstLeagueCompetition.totalEditions) {
				seasonsTopTotal = firstLeagueCompetition.totalEditions;
				if (secondLeagueCompetition && secondLeagueCompetition.totalEditions) {
					seasonsTopTotal2 = secondLeagueCompetition.totalEditions;
					const totalEditions = firstLeagueCompetition.totalEditions + secondLeagueCompetition.totalEditions;
					seasonsRanking = (team.seasonsTop || 0) * (firstLeagueCompetition.weight / totalEditions) + (team.seasonsTop2 || 0) * secondLeagueCompetition.weight / totalEditions;
				} else {
					seasonsRanking = (team.seasonsTop || 0) * firstLeagueCompetition.weight / firstLeagueCompetition.totalEditions;
				}
			}
		} else {
			if (secondLeagueCompetition) {
				if (team.category) {
					const parsedCategory = parseInt(team.category);
					if (!Number.isNaN(parsedCategory) && parsedCategory > 0) {
						categoryRanking = (1 / parsedCategory) * secondLeagueCompetition.weight;
					}
				}
				if (secondLeagueCompetition.totalEditions) {
					seasonsTopTotal2 = secondLeagueCompetition.totalEditions;
					seasonsRanking = (team.seasonsTop2 || 0) * secondLeagueCompetition.weight / secondLeagueCompetition.totalEditions;
				}
			}
		}
		return {
			seasonsRanking,
			categoryRanking,
			updatedLeagueCompetitionByCountryMap: leagueCompetitionByCountryMap,
			firstLeagueCompetition: firstLeagueCompetition || null,
			secondLeagueCompetition: secondLeagueCompetition || null,
			seasonsTopTotal,
			seasonsTopTotal2
		};
	}

	private static _getSeasonsTopPercentage(
		countryCode: string,
		competition: IDBCompetition | null,
		competition2: IDBCompetition | null,
		countryCode2?: string,
		seasonsTop?: number,
		seasonsTop2?: number
	): number {
		if (competition?.totalEditions !== undefined) {
			if (countryCode2 && competition2?.totalEditions !== undefined) {
				if (countryCode === 'DE' || countryCode === 'WL' || countryCode === 'NI') {
					const totalSeasonsTop = (seasonsTop !== undefined ? seasonsTop : 0) + (seasonsTop2 !== undefined ? seasonsTop2 : 0);
					const totalTotalEditions = competition?.totalEditions;
					return totalSeasonsTop / totalTotalEditions;
				} else {
					const totalSeasonsTop = (seasonsTop !== undefined ? seasonsTop : 0) + (seasonsTop2 !== undefined ? seasonsTop2 : 0);
					const totalTotalEditions = competition2?.totalEditions + competition?.totalEditions;
					return totalSeasonsTop / totalTotalEditions;
				}

			} else {
				if (seasonsTop !== undefined) {
					return seasonsTop / competition.totalEditions;
				}
				return 0;
			}
		}
		return 0;
	}

	private static _getChampionAndRunnerUpRankings = (
		team: IDBTeam,
		honoursByTeamId: Map<number, IHonours>,
		competitionsDictionary: INumberDictionary<IDBCompetition>
	): {
		ranking: number;
		runnerUpRanking: number;
		honours: IHonours;
	} => {
		const honoursOfTeam = honoursByTeamId.get(team.team_id);
		if (!honoursOfTeam) {
			return {
				ranking: 0,
				runnerUpRanking: 0,
				honours: {
					completeChampion: [],
					champion: [],
					runnerUp: [],
					completeRunnerUp: [],
					numberOfTitles: 0
				}
			};
		}
		const modifiedHonoursOfTeam = { ...honoursOfTeam };
		let ranking = 0;
		let processedChampions: ITitle[] = [];
		const titlesOfTeam: ITitle[] = Competitions.teamTitlesSorter(modifiedHonoursOfTeam.completeChampion, competitionsDictionary);
		let totalWeight = 0;
		let numberOfTitles: number = 0;

		let hasLeagueTitle = false;
		for (const champion of titlesOfTeam) {
			const competition = competitionsDictionary[champion.competitionId];
			if (competition) {
				processedChampions.push({
					...champion,
					competitionCode: `${competition.region_code}_${competition.competition_code}`,
					name: competition.name
				});
				ranking += competition.weight * champion.editions.length;
				totalWeight += competition.weight;
				numberOfTitles += champion.editions.length;
				if (competition.competition_code === 'L') {
					hasLeagueTitle = true;
				}
			}
		}
		processedChampions = hasLeagueTitle ? processedChampions.filter(c => c.competitionCode !== `${team.country_code}_L2`) : processedChampions;
		const averageChampionWeight = totalWeight > 0 ? totalWeight / processedChampions.length : 0;
		let processedRunnerUps: ITitle[] = [];
		let runnerUpRanking = 0;
		for (const runnerUp of modifiedHonoursOfTeam.completeRunnerUp) {
			const competition = competitionsDictionary[runnerUp.competitionId];
			if (competition) {
				if (
					processedChampions.length < 11 &&
					processedChampions.findIndex(x => x.competitionId === competition.competition_id) === -1 &&
					(competition.weight > averageChampionWeight || processedChampions.length < 4) &&
					processedRunnerUps.length < 3
				) {
					processedRunnerUps.push({
						...runnerUp,
						competitionCode: `${competition.region_code}_${competition.competition_code}`,
						name: competition.name
					});
				}
				runnerUpRanking += competition.weight * runnerUp.editions.length;
			}
		}
		processedRunnerUps = hasLeagueTitle ? processedRunnerUps.filter(c => c.competitionCode !== `${team.country_code}_L2`) : processedRunnerUps;
		const sortedRunnerUps = processedRunnerUps.sort((a, b) => {
			const competitionA = competitionsDictionary[a.competitionId];
			const competitionB = competitionsDictionary[b.competitionId];
			if (competitionA && competitionB) {
				if (competitionA.weight > competitionB.weight) {
					return -1;
				}
			}
			return 1;
		});
		return {
			ranking,
			runnerUpRanking,
			honours: {
				...modifiedHonoursOfTeam,
				champion: processedChampions,
				runnerUp: sortedRunnerUps,
				numberOfTitles
			}
		};

	}

	/**
	 * 
	 * @param team
	 * @param honoursByTeamId
	 * @param competitionsDictionary
	 * @param competitionsCodesMap
	 * @param leagueCompetitionByCountryMap
	 * @returns noTitleRanking: history weights more than current category
	 * @returns overallRanking: addition of all the rankings. just one third of runnerUp ranking
	 * @returns seasonsTopPercentage
	 * @returns honours: processed honours
	 */
	private static _processTeamRankings = (
		team: IDBTeam,
		honoursByTeamId: Map<number, IHonours>,
		competitionsDictionary: INumberDictionary<IDBCompetition>,
		competitionsCodesMap: Map<string, IDBCompetition>,
		leagueCompetitionByCountryMap: Map<string, IDBCompetition>
	): {
		noTitleRanking: number;
		overallRanking: number;
		seasonsTopPercentage: number;
		honours: IHonours;
		updatedLeagueCompetitionByCountryMap: Map<string, IDBCompetition>,
		seasonsTopTotal: number,
		seasonsTopTotal2: number
	} => {

		const { seasonsRanking, categoryRanking, updatedLeagueCompetitionByCountryMap, firstLeagueCompetition, secondLeagueCompetition, seasonsTopTotal, seasonsTopTotal2 } = Teams._getSeasonsAndCategoryRanking(team, competitionsCodesMap, leagueCompetitionByCountryMap);
		const seasonsTopPercentage = Teams._getSeasonsTopPercentage(team.country_code, firstLeagueCompetition, secondLeagueCompetition, team.country_code2, team.seasonsTop, team.seasonsTop2);
		const { ranking, runnerUpRanking, honours } = Teams._getChampionAndRunnerUpRankings(team, honoursByTeamId, competitionsDictionary);

		// History weights more than current category
		const noTitleRanking = seasonsRanking * (2 / 3) + categoryRanking * (1 / 3);
		return {
			noTitleRanking,
			overallRanking: ranking + runnerUpRanking / 3 + noTitleRanking,
			seasonsTopPercentage,
			honours,
			updatedLeagueCompetitionByCountryMap,
			seasonsTopTotal,
			seasonsTopTotal2
		};
	}

	public static sortTitles(titles: ITitle[], competitions: Map<number, IDBCompetition>): ITitle[] {
		return titles.sort((a, b) => {
			const competitionA = competitions.get(a.competitionId);
			const competitionB = competitions.get(b.competitionId);
			if (competitionA && competitionB) {
				if (competitionA.isContinental) {
					if (competitionB.isInternational) {
						return -1;
					} else if (competitionB.isContinental) {
						if (competitionA.weight > competitionB.weight) {
							return -1;
						} else {
							return 1;
						}
					} else {
						return 1;
					}

				} else if (competitionA.isInternational) {
					if (!competitionB.isInternational) {
						return 1;
					} else {
						if (competitionA.weight > competitionB.weight) {
							return -1;
						} else {
							return 1;
						}
					}
				} else {
					if (competitionB.isContinental || competitionB.isInternational) {
						return -1;
					} else {
						if (competitionA.weight > competitionB.weight) {
							return -1;
						} else {
							return 1;
						}
					}
				}
			}
			return -1;
		});
	}

	public static filterByConfederation = (teams: ITeam[], scarves: IScarf[], confederations: ConfederationsEnum[]): IScarfInfo[] => {
		const currentGroupScarves: IScarfInfo[] = [];
		const confederationsSet = new Set();
		for (const confederation of confederations) {
			confederationsSet.add(confederation);
		}

		const scarvesTeamIdMap: Map<number, IScarf[]> = new Map();
		for (const scarf of scarves) {
			const scarvesOfTeam = scarvesTeamIdMap.get(scarf.teamId);
			if (scarvesOfTeam) {
				scarvesTeamIdMap.set(scarf.teamId, scarvesOfTeam.concat([scarf]));
			} else {
				scarvesTeamIdMap.set(scarf.teamId, [scarf]);
			}
		}
		for (const team of teams) {
			const confederationOfTeam = Countries.getConfederationOfCountry(team.countryCode);
			if (confederationsSet.has(confederationOfTeam)) {
				const scarvesOfTeam = scarvesTeamIdMap.get(team.teamId);
				if (scarvesOfTeam && scarvesOfTeam.length > 0) {
					const stadium = team.stadium;
					const city = team.city;
					if (stadium && city) {
						currentGroupScarves.push({
							scarves: scarvesOfTeam,
							city,
							team,
							stadium
						});
					}
				}
			}
		}
		return currentGroupScarves;
	}

	public static getTeamsOfCountry = (teamsByCountryCode: IStringDictionary<ITeam[]>, regionCode: string): ITeam[] => {
		switch (regionCode) {
			case 'DR': {
				return (teamsByCountryCode['DE'] ?? []).filter(team => team.countryCode2 === 'DR');
			}
			case 'UK': {
				return [
					...(teamsByCountryCode['EN'] ?? []),
					...(teamsByCountryCode['SC'] ?? []),
					...(teamsByCountryCode['NI'] ?? []),
					...(teamsByCountryCode['WL'] ?? []),
				];
			}
			case 'YU': {
				return [
					...(teamsByCountryCode['HR'] ?? []),
					...(teamsByCountryCode['RS'] ?? []),
					...(teamsByCountryCode['BH'] ?? []),
					...(teamsByCountryCode['XK'] ?? []),
					...(teamsByCountryCode['SI'] ?? []),
					...(teamsByCountryCode['MD'] ?? []),
					...(teamsByCountryCode['ME'] ?? []),
				];
			}
			case 'UR': {
				return [
					...(teamsByCountryCode['RU'] ?? []),
					...(teamsByCountryCode['LT'] ?? []),
					...(teamsByCountryCode['LV'] ?? []),
					...(teamsByCountryCode['EE'] ?? []),
					...(teamsByCountryCode['KZ'] ?? []),
					...(teamsByCountryCode['MO'] ?? []),
					...(teamsByCountryCode['KY'] ?? []),
					...(teamsByCountryCode['UA'] ?? []),
					...(teamsByCountryCode['UB'] ?? []),
					...(teamsByCountryCode['TM'] ?? []),
					...(teamsByCountryCode['BY'] ?? []),
					...(teamsByCountryCode['GE'] ?? []),
					...(teamsByCountryCode['AZ'] ?? []),
				];
			}
			case 'ZK': {
				return [
					...(teamsByCountryCode['CZ'] ?? []),
					...(teamsByCountryCode['SK'] ?? []),
				]
			}
			default: {
				return teamsByCountryCode[regionCode] ?? [];
			}
		}

	}
}
