import React, { PureComponent } from 'react';
import { IFilterAreaCombinedProps } from '../../interfaces';
import { EnumSection, OrderMatchCompetition, MatchOrderCategoriesEnum, ConfederationsEnum } from '../../interfaces/clientInterfaces';
import rootSelector from '../selectors/filterArea';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { StyledHeader } from './Header';
import * as actions from '../../actions';
import { ASCENDING_KEY, DESCENDING_KEY, CHRONO_ORDER_KEY, ALL_COUNTRIES_NAME, COMPETITION_ORDER_KEY, ROUND_ORDER_KEY } from '../constants';

const MatchFilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 400px;
	flex: 0 1 100%;
	overflow-y: scroll;
`;

const ConfederationsFilterContainer = styled.div`
	max-height: 130px;
	overflow-x: scroll;
	max-width: 300px; 
	display: flex;
`;

const ImageFilterSubContainer = styled.div`
	display: flex;
	flex: 0 1 70%;
	flex-direction: column;
	cursor: pointer;
`;

const MatchSortContainer = styled.div`
	display: flex;
	flex: 0 1 100%;
	margin: auto;
`;

interface IStyledFilterHeader {
	isSelected: boolean;
}
const StyledFilterHeader = styled(StyledHeader)<IStyledFilterHeader>`
	cursor: pointer;
	background-color: ${props => props.isSelected ? '#F89504' : 'transparent'};
	a {
		color: ${props => props.isSelected ? 'white' : 'black'};
	}
`;


const StyledFilterImageHeader = styled(StyledHeader)`
	font-size: 14px;
	text-align: center;
	:hover {
		background-color: transparent;
		a {
			color: white;
		}
		i {
			color: white;
		}
	}
`;

interface IStyledStyledCompetitionFilter {
	isSelected: boolean;
}
const StyledFilterImageContainer = styled(StyledHeader)<IStyledStyledCompetitionFilter>`
	cursor: pointer;
	font-size: 12px;
	width: 60px;
	background-color: ${props => props.isSelected ? '#F89504' : 'transparent'};
	:hover {
		background-color: ${props => props.isSelected ? '#F89504 !important' : 'transparent !important;'};
	}
	a {
		color: ${props => props.isSelected ? 'white' : 'black'};
	}
	img {
		width: 60px;
	}
`;

const UCL_NAME = 'UEFA Champions League/European Cup';
const UEL_NAME = 'UEFA Europa League/UEFA Cup';
const COL_NAME = 'UEFA Conference League';
const UCW_NAME = 'UEFA Cup Winners´ Cup';
const USC_NAME = 'UEFA Super Cup';
const CWC_NAME = 'FIFA Club World Cup';
const FIC_NAME = 'Intercontinental Cup';
const LIB_NAME = 'CONMBEBOL Libertadores';
const SUD_NAME = 'CONMBEBOL Sudamericana';
const CIT_NAME = 'Coppa Italia';
const CES_NAME = 'Copa del Rey';
const FWC_NAME = 'FIFA World Cup';
const UEC_NAME = 'UEFA Euro';
const CAT_NAME = 'ÖFB Cup';

const UEFA = 'UEFA';
const CONMEBOL = 'CONMEBOL';
const CONCACAF = 'CONCACAF';
const CAF = 'CAF';
const AFC = 'AFC';

class FilterArea extends PureComponent<IFilterAreaCombinedProps, { }> {

	private _renderConfederationFilters = () => {
		if (this.props.section === EnumSection.matches || this.props.currentCountry !== ALL_COUNTRIES_NAME) {
			return null;
		}
		return (
			<ConfederationsFilterContainer>
				<ImageFilterSubContainer>
					<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterConfederation(ConfederationsEnum.eu))}>
						{UEFA}
						<StyledFilterImageContainer isSelected={this.props.orderBy.confederations.findIndex(x => x === ConfederationsEnum.eu) > -1}>
							<img
								title={UEFA}
								src={'../../../img/shield/EU_EC.png'}
								alt={UEFA}
							/>
						</StyledFilterImageContainer>
					</StyledFilterImageHeader>
				</ImageFilterSubContainer>
				<ImageFilterSubContainer>
					<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterConfederation(ConfederationsEnum.sa))}>
						{CONMEBOL}
						<StyledFilterImageContainer isSelected={this.props.orderBy.confederations.findIndex(x => x === ConfederationsEnum.sa) > -1}>
							<img
								title={CONMEBOL}
								src={'../../../img/shield/SA_CON.png'}
								alt={CONMEBOL}
							/>
						</StyledFilterImageContainer>
					</StyledFilterImageHeader>
				</ImageFilterSubContainer>
				<ImageFilterSubContainer>
					<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterConfederation(ConfederationsEnum.na))}>
						{CONCACAF}
						<StyledFilterImageContainer isSelected={this.props.orderBy.confederations.findIndex(x => x === ConfederationsEnum.na) > -1}>
							<img
								title={CONCACAF}
								src={'../../../img/shield/NA_CON.png'}
								alt={CONCACAF}
							/>
						</StyledFilterImageContainer>
					</StyledFilterImageHeader>
				</ImageFilterSubContainer>
				<ImageFilterSubContainer>
					<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterConfederation(ConfederationsEnum.af))}>
						{CAF}
						<StyledFilterImageContainer isSelected={this.props.orderBy.confederations.findIndex(x => x === ConfederationsEnum.af) > -1}>
							<img
								title={CAF}
								src={'../../../img/shield/AF_CAF.png'}
								alt={CAF}
							/>
						</StyledFilterImageContainer>
					</StyledFilterImageHeader>
				</ImageFilterSubContainer>
				<ImageFilterSubContainer>
					<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterConfederation(ConfederationsEnum.as))}>
						{AFC}
						<StyledFilterImageContainer isSelected={this.props.orderBy.confederations.findIndex(x => x === ConfederationsEnum.as) > -1}>
							<img
								title={AFC}
								src={'../../../img/shield/AS_AFC.png'}
								alt={AFC}
							/>
						</StyledFilterImageContainer>
					</StyledFilterImageHeader>
				</ImageFilterSubContainer>
			</ConfederationsFilterContainer>
		);
	}

	// private _renderGalleryFilters = () => {
	// 	if (this.props.section !== EnumSection.gallery) {
	// 		return null;
	// 	}
	// 	return (
	// 		<>
	// 			<StyledFilterHeader
	// 				isSelected={true}
	// 				onClick={() => this.props.dispatch(actions.changeOrderAscending())}
	// 			>
	// 				{this.props.orderBy.ascending ? ASCENDING_KEY : DESCENDING_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.honours}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory(OrderCategoriesEnum.honours))}
	// 			>
	// 				{HONOURS_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.numberTitles}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory(OrderCategoriesEnum.numberTitles))}
	// 			>
	// 				{NUMBER_HONOURS_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.seasonsTop}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory( OrderCategoriesEnum.seasonsTop))}
	// 			>
	// 				{SEASONS_TOP_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.alphabetically}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory( OrderCategoriesEnum.alphabetically))}
	// 			>
	// 				{ALPHABETICALLY_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.foundation}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory( OrderCategoriesEnum.foundation))}
	// 			>
	// 				{YEAR_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.category}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory( OrderCategoriesEnum.category))}
	// 			>
	// 				{CATEGORY_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 			<StyledFilterHeader
	// 				isSelected={this.props.orderBy.category === OrderCategoriesEnum.stadium}
	// 				onClick={() => this.props.dispatch(actions.changeOrderCategory( OrderCategoriesEnum.stadium))}
	// 			>
	// 				{STADIUM_ORDER_KEY}
	// 			</StyledFilterHeader>
	// 		</>
	// 	);
	// }
	private _renderMatchesFilters = () => {
		if (this.props.section !== EnumSection.matches) {
			return null;
		}
		return (
			<>
				<MatchFilterContainer>	
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.ucl))}>
							{UCL_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.ucl) > -1}>
								<img
									title={UCL_NAME}
									src={'../../../img/shield/EU_UCL.png'}
									alt={UCL_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.uel))}>
							{UEL_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.uel) > -1}>
								<img
									title={UEL_NAME}
									src={'../../../img/shield/EU_UEL.png'}
									alt={UEL_NAME}
								/>
							</StyledFilterImageContainer>
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.ucw))}>
							{UCW_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.ucw) > -1}>
								<img
									title={UCW_NAME}
									src={'../../../img/shield/EU_UCW.png'}
									alt={UCW_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.usc))}>
							{USC_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.usc) > -1}>
								<img
									title={USC_NAME}
									src={'../../../img/shield/EU_USC.png'}
									alt={USC_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.uco))}>
							{COL_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.uco) > -1}>
								<img
									title={COL_NAME}
									src={'../../../img/shield/EU_COL.png'}
									alt={COL_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.cwc))}>
							{CWC_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.cwc) > -1}>
								<img
									title={CWC_NAME}
									src={'../../../img/shield/FF_CWC.png'}
									alt={CWC_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.fic))}>
							{FIC_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.fic) > -1}>
								<img
									title={FIC_NAME}
									src={'../../../img/shield/FF_IC.png'}
									alt={FIC_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.lib))}>
							{LIB_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.lib) > -1}>
								<img
									title={LIB_NAME}
									src={'../../../img/shield/SA_LIB.png'}
									alt={LIB_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.sud))}>
							{SUD_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.sud) > -1}>
								<img
									title={SUD_NAME}
									src={'../../../img/shield/SA_SUD.png'}
									alt={SUD_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.cit))}>
							{CIT_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.cit) > -1}>
								<img
									title={CIT_NAME}
									src={'../../../img/shield/IT_C.png'}
									alt={CIT_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.ces))}>
							{CES_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.ces) > -1}>
								<img
									title={CES_NAME}
									src={'../../../img/shield/ES_C.png'}
									alt={CES_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.cat))}>
							{CAT_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.cat) > -1}>
								<img
									title={CAT_NAME}
									src={'../../../img/shield/AT_C.png'}
									alt={CAT_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.fwc))}>
							{FWC_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.fwc) > -1}>
								<img
									title={FWC_NAME}
									src={'../../../img/shield/FF_WC.png'}
									alt={FWC_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
					<ImageFilterSubContainer>
						<StyledFilterImageHeader onClick={() => this.props.dispatch(actions.filterCompetition(OrderMatchCompetition.uec))}>
							{UEC_NAME}
							<StyledFilterImageContainer isSelected={this.props.orderBy.matchCompetition.findIndex(x => x === OrderMatchCompetition.uec) > -1}>
								<img
									title={UEC_NAME}
									src={'../../../img/shield/EU_EC.png'}
									alt={UEC_NAME}
								/>
							</StyledFilterImageContainer>
							
						</StyledFilterImageHeader>
					</ImageFilterSubContainer>
				</MatchFilterContainer>
				<MatchSortContainer>
					<ImageFilterSubContainer>
						<StyledFilterHeader
							isSelected={true}
							onClick={() => this.props.dispatch(actions.changeMatchOrderAscending())}
						>
							{this.props.orderBy.matchAscending ? ASCENDING_KEY : DESCENDING_KEY}
						</StyledFilterHeader>
						<StyledFilterHeader
							isSelected={this.props.orderBy.matchCategory.findIndex(c => c === MatchOrderCategoriesEnum.chronologically) > -1}
							onClick={() => this.props.dispatch(actions.changeMatchOrder(MatchOrderCategoriesEnum.chronologically))}
						>
							{CHRONO_ORDER_KEY}
						</StyledFilterHeader>
						<StyledFilterHeader
							isSelected={this.props.orderBy.matchCategory.findIndex(c => c === MatchOrderCategoriesEnum.competition) > -1}
							onClick={() => this.props.dispatch(actions.changeMatchOrder(MatchOrderCategoriesEnum.competition))}
						>
							{COMPETITION_ORDER_KEY}
						</StyledFilterHeader>
						<StyledFilterHeader
							isSelected={this.props.orderBy.matchCategory.findIndex(c => c === MatchOrderCategoriesEnum.round) > -1}
							onClick={() => this.props.dispatch(actions.changeMatchOrder(MatchOrderCategoriesEnum.round))}
						>
							{ROUND_ORDER_KEY}
						</StyledFilterHeader>
					</ImageFilterSubContainer>
				</MatchSortContainer>
			</>	
		);
	}

	private _renderRMMatchesFilters = () => {
		if (this.props.section !== EnumSection.rm) {
			return null;
		}
		return (
			<>
				<MatchSortContainer>
					<ImageFilterSubContainer>
						<StyledFilterHeader
							isSelected={true}
							onClick={() => this.props.dispatch(actions.changeMatchOrderAscending())}
						>
							{this.props.orderBy.matchAscending ? ASCENDING_KEY : DESCENDING_KEY}
						</StyledFilterHeader>
						<StyledFilterHeader
							isSelected={this.props.orderBy.matchCategory.findIndex(c => c === MatchOrderCategoriesEnum.chronologically) > -1}
							onClick={() => this.props.dispatch(actions.changeMatchOrder(MatchOrderCategoriesEnum.chronologically))}
						>
							{CHRONO_ORDER_KEY}
						</StyledFilterHeader>
						<StyledFilterHeader
							isSelected={this.props.orderBy.matchCategory.findIndex(c => c === MatchOrderCategoriesEnum.competition) > -1}
							onClick={() => this.props.dispatch(actions.changeMatchOrder(MatchOrderCategoriesEnum.competition))}
						>
							{COMPETITION_ORDER_KEY}
						</StyledFilterHeader>
						<StyledFilterHeader
							isSelected={this.props.orderBy.matchCategory.findIndex(c => c === MatchOrderCategoriesEnum.round) > -1}
							onClick={() => this.props.dispatch(actions.changeMatchOrder(MatchOrderCategoriesEnum.round))}
						>
							{ROUND_ORDER_KEY}
						</StyledFilterHeader>
					</ImageFilterSubContainer>
				</MatchSortContainer>
			</>	
		);
	}

	public render() {
		return (
			<>
				{this._renderConfederationFilters()}
				{/* {this._renderGalleryFilters()} */}
				{this._renderMatchesFilters()}
				{this._renderRMMatchesFilters()}
			</>
		);
	}
}

export default connect(rootSelector)(FilterArea);


