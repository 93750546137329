import { INumberDictionary, IStringDictionary } from '../interfaces';
import { ConfederationsEnum, IOrderBy, IPagingInfo, IScarfInfo, IScarfUpdate, OrderCategoriesEnum } from '../interfaces/clientInterfaces';
import { IScarf, ITeam } from '../interfaces/clientInterfaces';
import { IDBScarf } from '../interfaces/dbInterfaces';
import { ALL_COUNTRIES_NAME, SELECTED_COUNTRIES } from '../modules/constants';
import { Countries } from './countries';
import { Teams } from './teams';

export class GroupScarves {


	public static sortScarvesInfo(scarves: IScarfInfo[], category: OrderCategoriesEnum, ascending: boolean) {
		switch (category) {
			case OrderCategoriesEnum.alphabetically: {
				return scarves.sort((a, b) => ascending ? (a.team.teamCode > b.team.teamCode ? 1 : -1) : (a.team.teamCode < b.team.teamCode ? 1 : -1));
			}
			case OrderCategoriesEnum.category: {
				return scarves.sort((a, b) => {
					if (!a.team.category) {
						return -1;
					}
					if (!b.team.category) {
						return 1;
					}
					if (a.team.category > b.team.category) {
						return ascending ? -1 : 1;
					} else if (a.team.category < b.team.category) {
						return ascending ? 1 : -1;
					} else {
						return ascending ? (a.team.teamCode > b.team.teamCode ? -1 : 1) : (a.team.teamCode < b.team.teamCode ? -1 : 1);
					}
				});
			}
			case OrderCategoriesEnum.foundation: {
				return scarves.sort((a, b) => {
					if (!a.team.founded) {
						return -1;
					}
					if (!b.team.founded) {
						return 1;
					}
					if (a.team.founded > b.team.founded) {
						return ascending ? -1 : 1;
					} else if (a.team.founded < b.team.founded) {
						return ascending ? 1 : -1;
					} else {
						return ascending ? (a.team.founded > b.team.founded ? -1 : 1) : (a.team.founded < b.team.founded ? -1 : 1);
					}
				});
			}
			case OrderCategoriesEnum.seasonsTop: {
				return scarves.sort((a, b) => {
					if (a.team.seasonsTopPercentage !== undefined && b.team.seasonsTopPercentage !== undefined) {
						if (a.team.seasonsTopPercentage > b.team.seasonsTopPercentage) {
							return ascending ? 1 : -1;
						} else if (a.team.seasonsTopPercentage < b.team.seasonsTopPercentage) {
							return ascending ? -1 : 1;

						} else {
							if (a.team.overallRanking < b.team.overallRanking) {
								return ascending ? -1 : 1;
							} else {
								if (a.team.category && b.team.category && a.team.category > b.team.category) {
									return ascending ? 1 : -1;
								} else {
									return -1;
								}
							}
						}
					}
					return 1;
				});
			}
			case OrderCategoriesEnum.honours: {
				return scarves.sort((a, b) => {
					if (a.team.overallRanking > b.team.overallRanking) {
						return ascending ? 1 : -1;
					} else if (a.team.overallRanking < b.team.overallRanking) {
						return ascending ? -1 : 1;
					} else {
						if (!a.team.category) {
							return 1;
						}
						if (!b.team.category) {
							return -1;
						}
						if (a.team.category > b.team.category) {
							return ascending ? -1 : 1;
						} else if (a.team.category < b.team.category) {
							return ascending ? 1 : -1;
						} else {
							return ascending ? (a.team.teamCode > b.team.teamCode ? -1 : 1) : (a.team.teamCode < b.team.teamCode ? -1 : 1);
						}
					}
				});
			}
			case OrderCategoriesEnum.numberTitles: {
				return scarves.sort((a, b) => {
					if (!a.team.honours.completeChampion) {
						return 1;
					}
					if (!b.team.honours.completeChampion) {
						return -1;
					}
					if (a.team.honours.numberOfTitles > b.team.honours.numberOfTitles) {
						return ascending ? 1 : -1;
					} else if (a.team.honours.numberOfTitles < b.team.honours.numberOfTitles) {
						return ascending ? -1 : 1;
					} else {
						if (a.team.overallRanking > b.team.overallRanking) {
							return ascending ? 1 : -1;
						} else if (a.team.overallRanking < b.team.overallRanking) {
							return ascending ? -1 : 1;
						} else {
							if (!a.team.category) {
								return 1;
							}
							if (!b.team.category) {
								return -1;
							}
							if (a.team.category > b.team.category) {
								return ascending ? -1 : 1;
							} else if (a.team.category < b.team.category) {
								return ascending ? 1 : -1;
							} else {
								return ascending ? (a.team.teamCode > b.team.teamCode ? -1 : 1) : (a.team.teamCode < b.team.teamCode ? -1 : 1);
							}
						}
					}
				});
			}
			case OrderCategoriesEnum.population: {
				return scarves.sort((a, b) => ascending ? (a.city.population > b.city.population ? 1 : -1) : (a.city.population < b.city.population ? 1 : -1));
			}
			case OrderCategoriesEnum.stadium: {
				return scarves.sort((a, b) => ascending ? (a.stadium.capacity > b.stadium.capacity ? 1 : -1) : (a.stadium.capacity < b.stadium.capacity ? 1 : -1));
			}
			default: {
				return scarves;
			}
		}
	}


	public static getScarfGroups = (
		scarves: IScarf[],
		teamsByCountryCode: IStringDictionary<ITeam[]>,
		currentCountry: string,
		orderBy: IOrderBy,
		pagingInfo: IPagingInfo,
		teams: ITeam[],
		dbScarves: IDBScarf[],
		scarvesByTeamMap: INumberDictionary<IScarf[]>
	): {
		currentGroupScarves: IScarfInfo[];
		lastScarves: IScarfUpdate[];
		totalPages: number;
	} => {
		let currentGroupScarves: IScarfInfo[] = [];
		const shouldSelectAll = currentCountry === SELECTED_COUNTRIES && !Object.keys(orderBy.countryCodes).some(key => orderBy.countryCodes[key] === true);
		const teamMap = new Map<string, ITeam>();
		if (currentCountry) {
			const teamsFromCountry = teamsByCountryCode[currentCountry];
			if (teamsFromCountry) {
				for (const team of teams) {
					teamMap.set(`${team.countryCode}_${team.teamCode}`, team);
					if (team.countryCode.indexOf(currentCountry as string) === 0 || currentCountry === ALL_COUNTRIES_NAME || shouldSelectAll) {
						const scarves = scarvesByTeamMap[team.teamId];
						if (scarves && scarves.length > 0) {
							currentGroupScarves.push({
								scarves,
								city: team.city,
								team,
								stadium: team.stadium
							});
						}
					}
				}
			} else if (Countries.isCountryCodeConfederation(currentCountry)) {
				currentGroupScarves = Teams.filterByConfederation(
					teams,
					scarves,
					[currentCountry as ConfederationsEnum]
				);
			} else {
				const teamsOfCountry = Teams.getTeamsOfCountry(teamsByCountryCode, currentCountry);
				if (teamsOfCountry) {
					for (const team of teamsOfCountry) {
						const scarvesOfTeam = scarvesByTeamMap[team.teamId];
						if (scarvesOfTeam && scarvesOfTeam.length > 0) {
							currentGroupScarves.push({
								scarves: scarvesOfTeam,
								city: team.city,
								team,
								stadium: team.stadium
							});
						}
					}
				}
			}
			
			currentGroupScarves = GroupScarves.sortScarvesInfo(currentGroupScarves, orderBy.category, orderBy.ascending);
		} else {
			for (const team of teams) {
				teamMap.set(`${team.countryCode}_${team.teamCode}`, team);
			}
		}
		const totalPages = Math.ceil(currentGroupScarves.length / pagingInfo.teamsPerPage);
		let lastScarves: IScarfUpdate[] = [];
		for (const scarf of dbScarves.slice(0, 20)) {
			const splittedName = scarf.scarfCode.split('_');
			const team = teamMap.get(splittedName[0] + '_' + splittedName[1]);
			if (scarf.date && team) {
				lastScarves.push({
					code: scarf.scarfCode,
					date: new Date(scarf.date),
					name: team.name,
					countryOrCompetitionCode: team.countryCode,
					isMatch: false,
					teamId: scarf.team_id
				});
			}
		}
		lastScarves = lastScarves.sort((a, b) => a.date > b.date ? -1 : 1);

		return { currentGroupScarves, lastScarves, totalPages };
	}



}
