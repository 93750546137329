import * as types from '../actionTypes';
import { IBasicInfo, OrderCategoriesEnum, EnumSection, OrderMatchCompetition, MatchOrderCategoriesEnum, ConfederationsEnum, ITrophyTooltip } from '../interfaces/clientInterfaces';
import { ITeam } from '../interfaces/clientInterfaces';
import { IDBCity, IDBCompetition, IDBEdition, IDBScarf, IDBScarfMatch, IDBStadium, IDBTeam } from '../interfaces/dbInterfaces';

interface IBaseActionType {
	type: string;
}

export function getScarves() {
	return {
		type: types.GET_SCARVES
	};
}

export function getBasicData() {
	return {
		type: types.GET_BASIC_DATA
	};
}


export interface IGetInfoOfCountryAction extends IBaseActionType {
	name: string;
}
export function getInfoOfCountry(name: string): IGetInfoOfCountryAction {
	return {
		type: types.GET_INFO_OF_COUNTRY,
		name
	};
}

export interface IPutBasicInfoAction extends IBaseActionType {
	basicInfo: IBasicInfo;
}
export function putBasicInfo(basicInfo: IBasicInfo): IPutBasicInfoAction {
	return {
		type: types.PUT_BASIC_INFO,
		basicInfo
	};
}

export interface IPutInfoAction extends IBaseActionType {
	dbTeams: IDBTeam[];
	dbEditions: IDBEdition[];
	dbCities: IDBCity[];
	dbCompetitions: IDBCompetition[];
	dbScarves: IDBScarf[];
	dbStadiums: IDBStadium[];
	dbMatchScarves: IDBScarfMatch[];
}
export function putDBInfo(
	dbTeams: IDBTeam[],
	dbEditions: IDBEdition[],
	dbCities: IDBCity[],
	dbCompetitions: IDBCompetition[],
	dbScarves: IDBScarf[],
	dbStadiums: IDBStadium[],
	dbMatchScarves: IDBScarfMatch[]
): IPutInfoAction {
	return {
		type: types.PUT_DB_INFO,
		dbTeams,
		dbEditions,
		dbCities,
		dbCompetitions,
		dbScarves,
		dbStadiums,
		dbMatchScarves
	};
}

export interface ISetInfoOfCountry extends IBaseActionType {
	name?: string;
}
export function setInfoOfCountry(name?: string): ISetInfoOfCountry {
	return {
		type: types.SET_INFO_OF_COUNTRY,
		name
	};
}

export interface IChangeOrderCategory extends IBaseActionType {
	category: OrderCategoriesEnum;
}
export function changeOrderCategory(category: OrderCategoriesEnum): IChangeOrderCategory {
	return {
		type: types.CHANGE_ORDER_CATEGORY,
		category
	};
}

export function changeOrderAscending(): IBaseActionType {
	return {
		type: types.CHANGE_ORDER_ASCENDING
	};
}

export function changeMatchOrderAscending(): IBaseActionType {
	return {
		type: types.CHANGE_MATCH_ORDER_ASCENDING
	};
}

export interface IChangeMatchOrderCategory extends IBaseActionType {
	category: MatchOrderCategoriesEnum;
}
export function changeMatchOrder(category: MatchOrderCategoriesEnum): IChangeMatchOrderCategory {
	return {
		type: types.CHANGE_MATCH_ORDER,
		category
	};
}

export function init(): IBaseActionType {
	return {
		type: types.INIT
	};
}

export interface ISetSectionNameAction extends IBaseActionType {
	section: EnumSection;
}
export function setSectionName(section: EnumSection): ISetSectionNameAction {
	return {
		type: types.SET_SECTION_NAME,
		section
	};
}

export interface ISetMapDetailTeam extends IBaseActionType {
	teamId: number;
}
export function setMapDetailTeam(teamId: number): ISetMapDetailTeam {
	return {
		type: types.SET_MAP_DETAIL_TEAM,
		teamId
	};
}

export interface ISetHomeDetailTeam extends IBaseActionType {
	teamId: number;
}
export function setHomeDetailTeam(teamId: number): ISetHomeDetailTeam {
	return {
		type: types.SET_HOME_DETAIL_TEAM,
		teamId
	};
}

export interface IChangeCurrentPage extends IBaseActionType {
	number: number;
}
export function changeCurrentPage(number: number): IChangeCurrentPage {
	return {
		type: types.CHANGE_CURRENT_PAGE,
		number
	};
}

export interface IChangeItemsPerPage extends IBaseActionType {
	number: number;
}
export function changeItemsPerPage(number: number): IChangeItemsPerPage {
	return {
		type: types.CHANGE_ITEMS_PER_PAGE,
		number
	};
}

export function showFilterArea(): IBaseActionType {
	return {
		type: types.SHOW_FILTER_AREA
	};
}

export function showTreeMap(): IBaseActionType {
	return {
		type: types.SHOW_TREE_MAP
	};
}

export function hideTreeMap(): IBaseActionType {
	return {
		type: types.HIDE_TREE_MAP
	};
}

export interface IUpdateViewPort extends IBaseActionType {
	width: number;
	height: number;
}
export function updateViewPort(width: number, height: number): IUpdateViewPort {
	return {
		type: types.UPDATE_VIEW_PORT,
		width,
		height
	};
}

export interface IFilterCompetition extends IBaseActionType {
	competition: OrderMatchCompetition;
}
export function filterCompetition(competition: OrderMatchCompetition): IFilterCompetition {
	return {
		type: types.FILTER_COMPETITION,
		competition
	};
}

export interface IFilterConfederation extends IBaseActionType {
	confederation: ConfederationsEnum;
}
export function filterConfederation(confederation: ConfederationsEnum): IFilterConfederation {
	return {
		type: types.FILTER_CONFEDERATION,
		confederation
	};
}


export interface ISetHighlightPopUp extends IBaseActionType {
	teamId: number;
}
export function setHighlightPopUp(teamId: number): ISetHighlightPopUp {
	return {
		type: types.SET_HIGHLIGHT_POP_UP,
		teamId
	};
}


export interface ILoadTeamImageAsync extends IBaseActionType {
	teams: ITeam[]
}
export function loadTeamImageAsync(teams: ITeam[]): ILoadTeamImageAsync {
	return {
		type: types.LOAD_TEAM_IMAGES_ASYNC,
		teams
	};
}

export interface ISetTrophyTooltip extends IBaseActionType {
	trophyTooltip: ITrophyTooltip | null;
}

export function setTrophyTooltip(trophyTooltip: ITrophyTooltip | null): ISetTrophyTooltip {
	return {
		type: types.SET_TROPHY_TOOLTIP,
		trophyTooltip
	};
}

export function setHonoursVisibility(): IBaseActionType {
	return {
		type: types.SET_HONOURS_VISIBILITY
	};
}
// LEGACY
export interface IHighlightAction extends IBaseActionType {
	teams: ITeam[];
}
export function getHighlights(teams: ITeam[]): IHighlightAction {
	return {
		type: types.GET_HIGHLIGHTS,
		teams
	};
}

export interface ISetHighlights extends IBaseActionType {
	highlights: any[];
	teams: ITeam[];
}
export function setHighlights(highlights: any[], teams: ITeam[]): ISetHighlights {
	return {
		type: types.SET_HIGHLIGHTS,
		highlights,
		teams
	};
}

export interface ISetReducedView extends IBaseActionType {
	isReduced: boolean;
}
export function setReducedView(isReduced: boolean): ISetReducedView {
	return {
		type: types.SET_REDUCED_VIEW,
		isReduced
	};
}

export interface ISelectByCountryCode extends IBaseActionType {
	countryCode: string;
}
export function selectByCountryCode(countryCode: string): ISelectByCountryCode {
	return {
		type: types.SELECT_BY_COUNTRY_CODE,
		countryCode
	};
}

export function confirmSelectByCountry(): IBaseActionType {
	return {
		type: types.CONFIRM_SELECT_BY_COUNTRY
	};
}

export interface IAddCompetition extends IBaseActionType {
	competitionId: number;
}
export function addCompetition(competitionId: number): IAddCompetition {
	return {
		type: types.ADD_COMPETITION,
		competitionId
	};
}

export interface IChangeRangeOfEvolution extends IBaseActionType {
	competitionIndex: number;
	startIndex: number;
	endIndex: number;
}
export function onChangeRangeOfEvolution(competitionIndex: number, startIndex: number, endIndex: number): IChangeRangeOfEvolution {
	return {
		type: types.CHANGE_RANGE_OF_EVOLUTION,
		competitionIndex,
		startIndex,
		endIndex
	};
}

export interface ISetTitleDetail extends IBaseActionType {
	team: ITeam;
}
export function onSetTitleDetail(team: ITeam): ISetTitleDetail {
	return {
		type: types.SET_TITLE_DETAIL,
		team
	};
}

export function hideDetailTrophyTooltip(): IBaseActionType {
	return {
		type: types.HIDE_DETAIL_TROPHY_TOOLTIP
	};
}

export interface IFilterByRegion extends IBaseActionType {
	region: string | null;
}
export function filterByRegion(region: string | null): IFilterByRegion {
	return {
		type: types.FILTER_BY_REGION,
		region,
	};
}
