import { IDropDownItem } from './components/DropDown/interfaces';
import { ConfederationsEnum, EnumSection, MatchOrderCategoriesEnum, OrderCategoriesEnum, OrderMatchCompetition } from '../interfaces/clientInterfaces';
import { IStore } from '../interfaces';

export const ALL_COUNTRIES_NAME = 'all';
export const MATCH_NAME = 'Matches';
export const REAL_MADRID_NAME = 'Real Madrid';
export const REAL_MADRID = 'real_madrid';
export const SELECT_BY_COUNTRIES = 'selectByCountry';
export const SELECT_BY_COUNTRIES_NAME = 'Select by country';
export const MATCH_LINK = 'games';
export const SELECTED_COUNTRIES = 'SelectedCountries';
export const COMPETITION_LINK = 'competitions';
export const COMPETITION_NAME = 'Select by competitions';

export const ALPHABETICALLY_ORDER_KEY = 'Alphabetically';
export const POP_ORDER_KEY = 'Population';
export const STADIUM_ORDER_KEY = 'Stadium capacity';
export const YEAR_ORDER_KEY = 'Foundation';
export const CATEGORY_ORDER_KEY = 'Category';
export const HONOURS_ORDER_KEY = 'Honours/Titles';
export const NUMBER_HONOURS_ORDER_KEY = 'Number of titles';
export const SEASONS_TOP_ORDER_KEY = 'Seasons in top category';

export const CHRONO_ORDER_KEY = 'Chronologically';
export const COMPETITION_ORDER_KEY = 'Competition';
export const ROUND_ORDER_KEY = 'Round';

export const PAGE_KEY = 'Page: ';
export const ITEMS_PER_PAGE = 'Items/page:';
export const SORT_TITLE_KEY = 'Sort by:';
export const ASCENDING_KEY = 'Ascending';
export const DESCENDING_KEY = 'Descending';

export const SHOW_MAP_KEY = 'Show on map';
export const SET_SIMPLE_VIEW = 'Change to simplified view';
export const SET_EXTENDED_VIEW = 'Change to extended view';
export const SHOW_MAP_STADIUM_KEY = 'See stadium on map';

export const FILTER_MATCHES_TOOLTIP = 'Filter scarves from matches';
export const FILTER_TEAMS_TOOLTIP = 'Filter scarves from teams';

export const CATEGORY_DROPDOWN_ITEMS: IDropDownItem[] = [
	{
		value: OrderCategoriesEnum.alphabetically,
		text: ALPHABETICALLY_ORDER_KEY
	},
	{
		value: OrderCategoriesEnum.category,
		text: CATEGORY_ORDER_KEY
	},
	{
		value: OrderCategoriesEnum.foundation,
		text: YEAR_ORDER_KEY
	},
	{
		value: OrderCategoriesEnum.population,
		text: POP_ORDER_KEY
	},
	{
		value: OrderCategoriesEnum.stadium,
		text: STADIUM_ORDER_KEY
	},
	{
		value: OrderCategoriesEnum.honours,
		text: HONOURS_ORDER_KEY
	}
];


export const ITEMS_PER_PAGE_ITEMS: IDropDownItem[] = [
	{
		value: '10',
		text: '10'
	},
	{
		value: '20',
		text: '20'
	},
	{
		value: '50',
		text: '50'
	}
];


export const matchCompetitionDefault = [
	OrderMatchCompetition.ucl,
	OrderMatchCompetition.uel,
	OrderMatchCompetition.ucw,
	OrderMatchCompetition.usc,
	OrderMatchCompetition.cwc,
	OrderMatchCompetition.fic,
	OrderMatchCompetition.fwc,
	OrderMatchCompetition.uec,
	OrderMatchCompetition.cit,
	OrderMatchCompetition.ces,
	OrderMatchCompetition.lib,
	OrderMatchCompetition.cat,
	OrderMatchCompetition.sud,
	OrderMatchCompetition.uco
];

export const initialConfederations = [
	ConfederationsEnum.eu,
	ConfederationsEnum.sa,
	ConfederationsEnum.na,
	ConfederationsEnum.af,
	ConfederationsEnum.as
];

export const initialState: IStore = {
	scarves: {
		scarves: [],
		scarvesByTeamMap: {},
		lastScarves: [],
		currentGroupScarves: [],
		exchangeableScarves: []
	},
	teams: {
		teams: [],
		teamsById: {},
		teamsByCountryCode: {},
	},
	matches: {
		currentMatchScarfGroups: [],
		matchScarfGroups: [],
		currentRMMatchScarfGroups: [],
		rMMatchScarfGroups: []
	},
	competitions: {
		competitionsWithEvolution: {},
		competitionsDictionary: {},
		evolutions: {},
		availableCompetitions: [],
		selectedCompetitions: {},
		selectedEvolutions: []
	},
	general: {
		countryCodes: [],
		homeDetailScarfInfo: null,
	},
	map: {
		stadiumCluster: {},
		mapDetailScarfInfo: null,
		teamStadiumCoordinatesMap: new Map<string, number[]>(),
	},
	orderAndPaging: {
		orderBy: {
			category: OrderCategoriesEnum.honours,
			ascending: false,
			confederations: initialConfederations,
			matchCompetition: matchCompetitionDefault,
			matchCategory: [MatchOrderCategoriesEnum.round, MatchOrderCategoriesEnum.competition],
			matchAscending: false,
			countryCodes: {},
			orderByRegion: ''

		},
		allPagingInfo: {
			currentPage: 0,
			totalPages: 0,
			teamsPerPage: 10
		}
	},
	ui: {
		section: EnumSection.home,
		currentCountry: '',
		showFilterArea: false,
		showTreeMap: false,
		viewPort: {
			width: 2000,
			height: 1000
		},
		isMobileViewActive: false,
		highlightPopUp: null,
		trophyTooltip: null,
		isReducedView: false,
		areTeamHonoursVisible: false,
		detailTrophyTooltip: null
	},
	sideTree: {
		matchesSideEntries: [],
		teamSideEntries: [],
		sideTreeEntries: []
	},
};
